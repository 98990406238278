import APP_CONTANTS from "../constants";
import Axios from "axios";
// import * as storageApi from './storage'

const apiRoot = APP_CONTANTS.apiUrl;

const authorizationHeader = token => {
  // const token = storageApi.get('token')

  if (token) {
    return {
      Authorization: `Bearer ${token}`
    };
  }

  return {};
};

const getPath = path => `${apiRoot}${path}`;

const getHeaders = (token, contentType) => {
  const headers = {
    ...authorizationHeader(token),
  };

  if (contentType === 'json') {
    headers['Content-Type'] = 'application/json';
  }

  return {
    headers: headers,
  };
};

const generateQueryString = payload => {
  if (!payload) {
    return "";
  }

  return Object.keys(payload).reduce((memo, key) => {
    if (payload[key] === undefined) {
      return memo;
    }

    if (Array.isArray(payload[key])) {
      return (
        memo +
        payload[key].reduce((arrMemo, v) => {
          if (typeof v === "object") {
            // eslint-disable-next-line no-param-reassign
            v = Object.values(v).join("|");
          }
          return `${arrMemo}${encodeURIComponent(key)}[]=${encodeURIComponent(
            v
          )}&`;
        }, "")
      );
    }

    if (payload[key] !== null && typeof payload[key] === "object") {
      return `${memo}${key}=${JSON.stringify(payload[key])}&`;
    }

    return `${memo}${encodeURIComponent(key)}=${encodeURIComponent(
      payload[key]
    )}&`;
  }, "?");
};


const parseJson = (response) => {
  if (response.status === 204) {
    return null;
  }

  if (response.status === 200 || response.status === 201) {
    return response.data;
  }
};

const errorResponse = (error) => {
  const customError = {
    data: error.response.data,
    status: error.response.status,
    message: error.message
  };
  throw customError;
};

const validatePlainContent = response => {
  if (response.ok) {
    return response;
  }
  const message = 'Error validating plain content'
  console.error({ error: response, message })
  throw new Error(message)
}

const parseBlob = (response) => response.blob()

export const post = (path, token, payload, options = {}) => {
  const contentType =
    typeof payload === "object" && !(payload instanceof FormData)
      ? "application/json"
      : "multipart/form-data";
  return Axios
    .post(getPath(path), payload, getHeaders(token, contentType))
    .then(parseJson)
    .catch(errorResponse);
};

export const put = (path, token, payload, options = {}) =>
  Axios
    .put(getPath(path), payload, getHeaders(token, "PUT"))
    .then(parseJson)
    .catch(errorResponse);

export const get = (path, token, payload, options = {}) =>
  Axios
    .get(`${getPath(path)}${generateQueryString(payload)}`, getHeaders(token))
    .then(parseJson)
    .catch(errorResponse);

export const getPlainContent = (path, payload) =>
  Axios
    .get(`${getPath(path)}${generateQueryString(payload)}`, getHeaders())
    .then(validatePlainContent);

export const getRaw = (path, payload) =>
  Axios
    .get(`${getPath(path)}${generateQueryString(payload)}`, getHeaders())
    .then(parseBlob);

export const del = (path, token, payload, options = {}) =>
  Axios.delete(getPath(path), { data: payload, ...getHeaders(token) })
    .then(parseJson)
    .catch(errorResponse);

  export const patch = (path, token, payload, options = {}) =>
  Axios.patch(getPath(path), payload, getHeaders(token))
    .then(parseJson)
    .catch(errorResponse);
