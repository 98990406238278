import ACTION_TYPE from "../actions/usersAction";

const initialState = {
  eligibleUsersToAddStock: [],
  doctorsInClinics: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.SET_ELIGIBLE_USERS_TO_ADD_STOCK:
      return {
        ...state,
        eligibleUsersToAddStock: action.payload
      };
    case ACTION_TYPE.SET_DOCTORS_IN_CLINICS:
      return {
        ...state,
        doctorsInClinics: action.payload
      };
    default:
      return state;
  }
}
