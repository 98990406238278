/*
 * This file is created by Vatsaly Patel
 * Note: If you are editing anything, please let me know - or do comment [it's compulsory]
 */

import {
  NOTIFICATION_NUMBER,
  LOGIN,
  LOGOUT,
  EDIT_CONTACT_DETAILS,
  PR_PATIENT_ID,
  HEAD_CLINIC_DATA,
  SUB_CLINIC_DATA,
  PR_PATIENT_DATA,
  CONSTANTS_DATA,
  SET_USER_BANK_DETAILS,
  SET_PR_PATIENT_WARNINGS
} from "../constants/commonConstant";

export const notificationNumber = data => ({
  type: NOTIFICATION_NUMBER,
  payload: data
});

// user login
export const loginUser = data => ({
  type: LOGIN,
  payload: data
});

// user logout
export const logoutUser = data => ({
  type: LOGOUT
});

export const editContactDetailsPR = data => ({
  type: EDIT_CONTACT_DETAILS,
  payload: data
});

export const setPrPatientId = data => ({
  type: PR_PATIENT_ID,
  payload: data
});

export const setHeadClinicData = data => ({
  type: HEAD_CLINIC_DATA,
  payload: data
});

export const setSubClinicData = data => ({
  type: SUB_CLINIC_DATA,
  payload: data
});

export const setPrPatientData = data => ({
  type: PR_PATIENT_DATA,
  payload: data
});

export const setConstantsData = data => ({
  type: CONSTANTS_DATA,
  payload: data
});

export const setUserBankDetails = data => ({
  type: SET_USER_BANK_DETAILS,
  payload: data
});

export const setPrPatientWarnings = data => ({
  type: SET_PR_PATIENT_WARNINGS,
  payload: data
});
