import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";

import appRoutes from "../../routes/appRoutes";
import { USER_TYPE } from "../../constants/common";
import otherStockIcon from "../../assets/images/other-stock.png"
import productStockIcon from "../../assets/images/product-stock.png"
import InstantTouchLogo from "../../assets/images/InstantTouchLogo.png";
import otherStockActiveIcon from "../../assets/images/other-stock-active.png"
import productStockActiveIcon from "../../assets/images/product-stock-active.png"


const SideNavBar = (props) => {
  const { ipad, closeNavDrawer, isICDoctor, userType } = props;
  const location = useLocation();
  const navbarItems = [
    {
      title: 'Users',
      route: appRoutes.logins,
      pathname: '',
      iconClasses: "fas fa-key font-15 primary-color",
      icon: '',
      activeIcon: '',
      displayFor: [
        USER_TYPE.SUPER_ADMIN,
      ]
    },
    {
      title: 'Clinics',
      route: appRoutes.clinics,
      pathname: '',
      iconClasses: "fas fa-clinic-medical font-15 primary-color",
      icon: '',
      activeIcon: '',
      displayFor: [
        USER_TYPE.SUPER_ADMIN,
      ]
    },
    {
      title: 'Bookings',
      route: appRoutes.bookings,
      pathname: '',
      iconClasses: "fas fa-calendar-check font-15 primary-color",
      icon: '',
      activeIcon: '',
      displayFor: [
        USER_TYPE.SUPER_ADMIN,
        USER_TYPE.ADMIN,
        USER_TYPE.ADMIN_DOCTOR,
        USER_TYPE.ADMIN_NURSE,
        USER_TYPE.NURSE,
        USER_TYPE.DERMAL_THERAPIST,
        USER_TYPE.ADMIN_DERMAL_THERAPIST
      ]
    },
    {
      title: 'Patients',
      route: appRoutes.patients,
      pathname: '',
      iconClasses: "fas fa-users font-15 primary-color",
      icon: '',
      activeIcon: '',
      displayFor: [
        USER_TYPE.SUPER_ADMIN,
        USER_TYPE.ADMIN,
        USER_TYPE.ADMIN_DOCTOR,
        USER_TYPE.ADMIN_NURSE,
        USER_TYPE.NURSE,
        USER_TYPE.DERMAL_THERAPIST,
        USER_TYPE.ADMIN_DERMAL_THERAPIST
      ]
    },
    {
      title: 'Practitioner Consents',
      route: appRoutes.practitionerConsents,
      pathname: '',
      iconClasses: "fas fa-file-signature font-15 primary-color",
      icon: '',
      activeIcon: '',
      displayFor: [
        USER_TYPE.SUPER_ADMIN,
        USER_TYPE.ADMIN,
        USER_TYPE.ADMIN_DOCTOR,
        USER_TYPE.ADMIN_NURSE,
        USER_TYPE.DOCTOR,
        USER_TYPE.NURSE,
        USER_TYPE.ADMIN_DERMAL_THERAPIST
      ]
    },
    {
      title: 'Injectables Stock',
      route: appRoutes.stocks,
      pathname: '',
      iconClasses: "fas fa-syringe font-15 primary-color",
      icon: '',
      activeIcon: '',
      displayFor: [
        USER_TYPE.SUPER_ADMIN,
        USER_TYPE.ADMIN,
        USER_TYPE.ADMIN_DOCTOR,
        USER_TYPE.ADMIN_NURSE,
        USER_TYPE.NURSE,
        USER_TYPE.DERMAL_THERAPIST,
        USER_TYPE.ADMIN_DERMAL_THERAPIST
      ]
    },
    {
      title: 'Other Stock',
      route: appRoutes.otherStocks,
      pathname: '/other-stock',
      iconClasses: "",
      icon: otherStockIcon,
      activeIcon: otherStockActiveIcon,
      displayFor: [
        USER_TYPE.SUPER_ADMIN,
        USER_TYPE.ADMIN,
        USER_TYPE.ADMIN_DOCTOR,
        USER_TYPE.ADMIN_NURSE,
        USER_TYPE.NURSE,
        USER_TYPE.DERMAL_THERAPIST,
        USER_TYPE.ADMIN_DERMAL_THERAPIST
      ]
    },
    {
      title: 'Product Stock',
      route: appRoutes.productStocks,
      pathname: '/product-stock',
      iconClasses: "",
      icon: productStockIcon,
      activeIcon: productStockActiveIcon,
      displayFor: [
        USER_TYPE.SUPER_ADMIN,
        USER_TYPE.ADMIN,
        USER_TYPE.ADMIN_DOCTOR,
        USER_TYPE.ADMIN_NURSE,
        USER_TYPE.NURSE,
        USER_TYPE.DERMAL_THERAPIST,
        USER_TYPE.ADMIN_DERMAL_THERAPIST
      ]
    },
    {
      title: 'Marketing',
      route: appRoutes.marketing,
      pathname: '',
      iconClasses: "fas fa-bullhorn font-15 primary-color",
      icon: '',
      activeIcon: '',
      displayFor: [
        USER_TYPE.SUPER_ADMIN,
        USER_TYPE.ADMIN,
        USER_TYPE.ADMIN_DOCTOR,
        USER_TYPE.ADMIN_NURSE,
        USER_TYPE.NURSE,
        USER_TYPE.DERMAL_THERAPIST,
        USER_TYPE.ADMIN_DERMAL_THERAPIST
      ]
    },
    {
      title: 'Reporting',
      route: appRoutes.reporting,
      pathname: '',
      iconClasses: "fas fa-chart-bar font-15 primary-color",
      icon: '',
      activeIcon: '',
      displayFor: [
        USER_TYPE.SUPER_ADMIN,
        USER_TYPE.ADMIN,
        USER_TYPE.ADMIN_DOCTOR,
        USER_TYPE.ADMIN_NURSE,
        USER_TYPE.ADMIN_DERMAL_THERAPIST
      ]
    },
    {
      title: 'Shifts',
      route: appRoutes.nurseShifts,
      pathname: '',
      iconClasses: "fa fa-calendar font-15 primary-color",
      icon: '',
      activeIcon: '',
      displayFor: [
        USER_TYPE.SUPER_ADMIN,
        USER_TYPE.ADMIN,
        USER_TYPE.ADMIN_NURSE,
        USER_TYPE.ADMIN_DERMAL_THERAPIST,
        USER_TYPE.ADMIN_DOCTOR
      ]
    },
    {
      title: 'Bank Details',
      route: appRoutes.bankDetails,
      pathname: '',
      iconClasses: " fas fa-university font-15 primary-color",
      icon: '',
      activeIcon: '',
      displayFor: [
        isICDoctor ? USER_TYPE.ADMIN_DOCTOR : null,
        isICDoctor ? USER_TYPE.DOCTOR : null,
      ]
    },
    {
      title: 'Payment Summaries',
      route: appRoutes.paymentSummary,
      pathname: '',
      iconClasses: "fas fa-money-bill font-15 primary-color",
      icon: '',
      activeIcon: '',
      displayFor: [
        isICDoctor ? USER_TYPE.ADMIN_DOCTOR : null,
        isICDoctor ? USER_TYPE.DOCTOR : null,
      ]
    },
    {
      title: 'Legal',
      route: appRoutes.legal,
      pathname: '',
      iconClasses: "fas fa-file-alt font-15 primary-color",
      icon: '',
      activeIcon: '',
      displayFor: [
        USER_TYPE.SUPER_ADMIN,
        USER_TYPE.ADMIN,
        USER_TYPE.ADMIN_DOCTOR,
        USER_TYPE.ADMIN_NURSE,
        USER_TYPE.DOCTOR,
        USER_TYPE.NURSE,
        USER_TYPE.DERMAL_THERAPIST,
        USER_TYPE.ADMIN_DERMAL_THERAPIST
      ]
    },
  ]

  return (
    <Box
      className={ipad === true ? "side-nav ipad-side-nav" : "side-nav"}
    >
      <div className="d-none-desktop">
        <img src={InstantTouchLogo} alt="IT Logo" className="side-nav-logo-ipad" />
      </div>
      <div
        className={ipad === true ? "ipad-close-nav" : "d-none"}
        onClick={() => { closeNavDrawer(false); }}
      >
        <i className="fas fa-times font-18 primary-color"></i>
      </div>
      <Box className="custom-li">
        {navbarItems.filter(({ displayFor }) => displayFor.includes(userType)).map(({ title, route, pathname, icon, activeIcon, iconClasses }) => <NavLink
          key={title}
          to={route}
          className="list-group-item list-group-item-action"
          href="#"
          onClick={() => { closeNavDrawer(false); }}
        >
          {pathname ?
            <>
              <img src={location.pathname.startsWith(pathname) ? activeIcon : icon} alt="Icon" width={17} height={17} style={{ marginTop: 4, marginRight: 16 }} className={iconClasses} />
              &nbsp;
            </>
            :
            <i style={{ marginTop: 4, marginRight: 16 }} className={iconClasses}></i>
          }
          <span style={{ padding: 0, marginTop: 4 }} className="insta-label font-15 secondary-font">{title}</span>
        </NavLink>
        )}
      </Box>
    </Box>
  );
}

export default SideNavBar;