import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import {
  Box,
  Radio,
  Tooltip,
  FormLabel,
  TextField,
  RadioGroup,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Typography
} from "@material-ui/core";

import Button from "../../components/button";
import CustomModal from "../../components/modalProps";
import { SMS_AND_EMAIL_TEMPLATES } from "../../constants/common";
import { getNotificationLogs } from "../../api/patients";

const SendNotificationForm = props => {
  const [radioBtnVal, setRadioBtnVal] = useState("");
  const [showNotifiedByModal, setShowNotifiedByModal] = useState(false);
  const [notifiedBy, setNotifiedBy] = useState();
  const templates = SMS_AND_EMAIL_TEMPLATES.filter(({ displayOnComponent }) =>
    displayOnComponent.includes(props.componentIdentifier)
  );

  const formik = useFormik({
    initialValues: {
      smsText: ""
    },
    validationSchema: Yup.object({
      smsText: Yup.string().required("Please enter the message")
    }),
    onSubmit: values => {
      props.onFormSubmit({...values, template: radioBtnVal});
    }
  });

  const handleRadioBtnChange = event => {
    setRadioBtnVal(event.target.value);
    const smsTextTemplate = _.find(templates, function(o) {
      return o.title === event.target.value;
    });
    let { template = "" } = smsTextTemplate;
    const {
      clinicName,
      clinicPhoneNumber,
      clinicBookingUrl,
      clientName,
      userFirstName,
      userLastName
    } = props;

    template = template
      .replace(/<sub-clinic>/g, clinicName)
      .replace(/<sub-clinic-phone>/g, clinicPhoneNumber)
      .replace(/<clinic-booking-url>/g, clinicBookingUrl)
      .replace(/<client-name>/g, clientName)
      .replace(/<user-firstName>/g, userFirstName)
      .replace(/<user-fullName>/g, `${userFirstName} ${userLastName}`);

    formik.values.smsText = template;
    formik.setFieldValue("smsText", template);
    formik.handleChange("smsText");
  };

  async function getNotifiedBy() {
    try {
      const params = { patientId: props.patientId };
      if(props.bookingId){
        params.bookingId = props.bookingId;
      }
      const token = props.commonReducers?.auth?.accessToken || "";
      const res = await getNotificationLogs(token, params);
      setNotifiedBy(res.data);
      setShowNotifiedByModal(true);
    } catch (error) {}
  }

  return (
    <Fragment>
      <Box
        component="form"
        textAlign="left"
        onSubmit={formik.handleSubmit}
        className="patient-notification-form"
      >
        <Box width={1} display="flex" alignItems="center" mb={1}>
          <FormControl fullWidth>
            <FormLabel className="insta-label font-15 gray">Message</FormLabel>
          </FormControl>
          <i
            className="fas fa-file fa-2x cursor-pointer primary-color"
            onClick={getNotifiedBy}
          ></i>
        </Box>
        <Box width={1}>
          <FormControl
            fullWidth
            component="fieldset"
            className="edtiStockRadioBtn"
          >
            <RadioGroup
              name="radioBtnGroup"
              id="radioBtnGroup"
              onChange={handleRadioBtnChange}
              value={radioBtnVal}
            >
              {templates.map(template => {
                return (
                  <FormControlLabel
                    key={template.title}
                    value={template.title}
                    control={<Radio />}
                    label={template.title}
                    className="radio-btns"
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          {formik.errors.radioBtnGroup && (
            <FormHelperText error>Please select options</FormHelperText>
          )}
          <TextField
            id="smsText"
            name="smsText"
            type="text"
            label=""
            multiline
            className="ic-input text-area"
            minRows={"6"}
            placeholder="Message"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.smsText}
            error={
              formik.touched.smsText && formik.errors.smsText ? true : false
            }
            helperText={
              formik.touched.smsText &&
              formik.errors.smsText &&
              formik.errors.smsText
            }
          />
        </Box>

        <Box width={1} textAlign="center" mt={4}>
          <Button type="submit" className="insta-button-primary" />
        </Box>
      </Box>
      <CustomModal
        useOneButton={true}
        cancelText="OKAY"
        title="Notified By"
        openModal={showNotifiedByModal}
        cancelEvent={() => setShowNotifiedByModal(false)}
        okayEvent={() => setShowNotifiedByModal(false)}
        modalBodyContent={
          <Box className="patient-notification-log-modal">
            {notifiedBy && notifiedBy.length ? (
              notifiedBy.map((value, index) => {
                console.log(value);
                return (
                  <Box p={1} width={1} textAlign="left" key={index}>
                    <Tooltip
                      title={
                        <Typography
                          style={{ color: "#fff" }}
                          className="insta-label font-14"
                        >
                          {value.notificationMsg}
                        </Typography>
                      }
                      placement="bottom"
                      arrow
                    >
                      <Box width={1} mt={0.5} className="cursor-pointer">
                        <Box mr={0.5} component="span" color="success.main">
                          <i className="fas fa-check"></i>
                        </Box>
                        <span>
                          {moment(value.createdAt).format("DD-MM-YYYY hh:mm A")}
                        </span>
                        <span style={{ marginLeft: 12 }}>
                          {value.userId.firstName + " " + value.userId.lastName}
                        </span>
                      </Box>
                    </Tooltip>
                  </Box>
                );
              })
            ) : (
              <p>No Data Found</p>
            )}
          </Box>
        }
      />
    </Fragment>
  );
};

// export default SendNotificationForm;

const mapStateToProps = state => ({
  commonReducers: state.commonReducers
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SendNotificationForm));

SendNotificationForm.defaultProps = {
  serverErrorMsg: "",
  showPostCareOptions: false
};

SendNotificationForm.propTypes = {
  onFormSubmit: PropTypes.func,
  serverErrorMsg: PropTypes.string,
  showPostCareOptions: PropTypes.bool
};
