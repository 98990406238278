import React, { Fragment, lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import setAuthToken from "../utils/setAuthToken";

import IndexLayout from "../containers/Layout/Dashboard";
import DashboardLayout from "../containers/Layout/Dashboard/DashboardLayout";
import PRDashboardLayout from "../containers/PR/Layout/PRLayout"; // Ourter dashboard layout

import PatientDetailsPage from "../pages/PR/PatientDetail/PatientDetailsLayout"; // Patient Detail Layout
import LoginPage from "../pages/Login";
import ForgotPassPage from "../pages/ForgotPassword/ForgotPasswordPage";
import CreatePassPage from "../pages/CreatePasswordPage";

// Routes
import appRoutes from "./appRoutes"; // nurse routes

// Loading Component
import Loading from "../containers/Loading";
const AddOtherStockPage = lazy(() => import("../pages/OtherStocks/AddStockPage"));
const AddInjectableStockPage = lazy(() => import("../pages/InjectableStocks/AddStockPage"));
const ViewInjectableStockPage = lazy(() => import("../pages/InjectableStocks/ViewStockPage"));
const ViewOtherStockPage = lazy(() => import("../pages/OtherStocks/ViewStockPage"));
const OtherStockPage = lazy(() => import("../pages/OtherStocks/StockPage"));
const InjectableStockPage = lazy(() => import("../pages/InjectableStocks/StockPage"));
const EditOtherStockPage = lazy(() => import("../pages/OtherStocks/EditStockPage"));
const EditInjectableStockPage = lazy(() => import("../pages/InjectableStocks/EditStockPage"));

const AddProductStockPage = lazy(() => import("../pages/ProductStocks/AddStockPage"));
const ViewProductStockPage = lazy(() => import("../pages/ProductStocks/ViewStockPage"));
const ProductStockPage = lazy(() => import("../pages/ProductStocks/StockPage"));
const EditProductStockPage = lazy(() => import("../pages/ProductStocks/EditStockPage"));

// Nurse pages
const BookingPage = lazy(() => import("../pages/Booking/Booking")); // booking page
const EditBookingPage = lazy(() => import("../pages/Booking/EditBooking")); // edit booking page
const ViewBookingPage = lazy(() => import("../pages/Booking/ViewBooking")); // view booking page
const PatientsListPage = lazy(() =>
  import("../pages/Patients/PatientsListPage")
); // patients list page
const PractitionerConsentsPage = lazy(() =>
  import("../pages/PractitionerConsents/PractitionerConsents")
); // Practitioner consents page
const AddPatientPage = lazy(() => import("../pages/Patients/AddPatientPage")); // Add New patient page
const NurseLegalPage = lazy(() => import("../pages/Legal/NurseLegalPage")); // Nurse Legal Page
const NurseProfilePage = lazy(() => import("../pages/Profile/NurseProfile")); //Nurse Profile

const EditPractitionerConsents = lazy(() =>
  import("../pages/PractitionerConsents/EditPractitionerConsents")
);

const MarketingPage = lazy(() => import("../pages/Marketing/MarketingPage")); // Nurse Marketing Page
const ChangePasswordPage = lazy(() => import("../pages/ChangePassword")); //Change password page

const AddPaymentPage = lazy(() => import("../pages/Payment/AddPaymentPage")); // Nurse payment
const ReportingPage = lazy(() => import("../pages/Reporting/ReportingPage")); // For admin users

const LoginsPage = lazy(() => import("../pages/Logins/LoginsPage")); // Super Admin Only
const AddLoginsPage = lazy(() => import("../pages/Logins/AddLoginsPage")); // Super Admin Only
const ClinicsPage = lazy(() => import("../pages/Clinics/ClinicsPage")); // Super Admin Only
const AddClinicsPage = lazy(() => import("../pages/Clinics/AddClinicsPage")); // Super Admin Only

const BankDetailsPage = lazy(() => import("../pages/BankDetails/index"));
const EditBankDetailsPage = lazy(() =>
  import("../pages/BankDetails/EditBankDetails/index")
);

const PaymentSummaryPage = lazy(() => import("../pages/PaymentSummary/index"));
const NurseShiftsPage = lazy(() => import("../pages/Shifts/NurseShiftsPage"));

// PR
const ContactDetailsForm = lazy(() =>
  import("../containers/PR/ContactDetailsForm/ContactDetailsForm")
); // PR first page, patient contact detail page
const ConsentForm = lazy(() =>
  import("../containers/PR/ConsentForms/ConsentForm")
);
const AddNewConsent = lazy(() =>
  import("../containers/PR/ConsentForms/AddNewConsent")
);
const MedicalHistory = lazy(() =>
  import("../containers/PR/MedicalHistory/MedicalHistory")
); // PR Medical History tab page
const S4DrugProtocolsList = lazy(() =>
  import("../containers/PR/S4DrugProtocols/S4DrugProtocolsList")
); // Drug protocol page PR
const AddS4DrugProtocolFrom = lazy(() =>
  import("../containers/PR/S4DrugProtocols/AddS4DrugProtocolForm")
); // Add S4 drug protocol form
const ViewS4DrugProtocol = lazy(() =>
  import("../containers/PR/S4DrugProtocols/ViewS4DrugProtocol")
); // View S4 drug protocol from view button click from table list

const OtherProtocolsList = lazy(() =>
  import("../containers/PR/OtherProtocols/OtherProtocolsList")
); // Drug protocol page PR
const AddOtherProtocol = lazy(() =>
  import("../containers/PR/OtherProtocols/AddOtherProtocol")
); // Add S4 drug protocol form
const ViewOtherProtocol = lazy(() =>
  import("../containers/PR/OtherProtocols/ViewOtherProtocol")
); // View S4 drug protocol from view button click from table list

// we will use this components for the restricted routes which can be accessed after login only
const RestrictedRoute = ({ isLoggedIn, path, component, exact = false }) => (
  <Fragment>
    {isLoggedIn === true ? (
      <Route exact={exact} path={`${path}`} component={component} />
    ) : (
      <Redirect to="/login" from={path} />
    )}
  </Fragment>
);

// We will use this component for public routes which cannot be access after login
const PublicRoute = ({ path, baseName, isLoggedIn, component, dashboardRoute }) => (
  <Fragment>
    {isLoggedIn === false ? (
      <Route path={`${path}`} component={component} />
    ) : (
      <Redirect
        to={"/" + baseName + dashboardRoute}
        from={path}
      />
    )}
  </Fragment>
);

const AppRoutes = () => {
  const commonReducers = useSelector((state) => state.commonReducers);
  const { auth } = commonReducers;

  useEffect(() => {
    setAuthToken(auth && auth.accessToken ? auth.accessToken : false);
  }, [auth]);

  let baseName = auth && auth.userType ? auth.userType : "";
  let dashboardRoute = "";

  if (baseName === "doctor") {
    dashboardRoute = appRoutes.practitionerConsents;
  } else if (baseName === "super_admin") {
    dashboardRoute = appRoutes.logins;
    baseName = "superadmin";
  } else {
    dashboardRoute = appRoutes.bookings;
  }

  return (
    <div>
      <Router>
        <Switch>
          <Redirect exact from="/" to="/login" />
          <Redirect exact from="/dashboard" to={dashboardRoute} />
          <PublicRoute
            path={appRoutes.login}
            component={LoginPage}
            isLoggedIn={commonReducers.isLoggedIn}
            baseName={baseName}
            dashboardRoute={dashboardRoute}
          />
          <PublicRoute
            path={appRoutes.forgotPassword}
            component={ForgotPassPage}
            isLoggedIn={commonReducers.isLoggedIn}
            baseName={baseName}
            dashboardRoute={dashboardRoute}
          />
          <PublicRoute
            path={appRoutes.createPassword}
            component={CreatePassPage}
            isLoggedIn={commonReducers.isLoggedIn}
            baseName={baseName}
            dashboardRoute={dashboardRoute}
          />
          {commonReducers.auth && commonReducers.auth.accessToken && (
            <Fragment>
              <Route path={"/" + baseName}>
                <Switch>
                  <Router basename={baseName}>
                    <Switch>
                      <IndexLayout>
                        <DashboardLayout>
                          <Suspense fallback={<Loading />}>
                            <RestrictedRoute
                              path={appRoutes.bookings}
                              component={BookingPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.editBooking + "/:id"}
                              component={EditBookingPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.createBooking}
                              component={EditBookingPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.addPayment + "/:id"}
                              component={AddPaymentPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.bankDetails}
                              component={BankDetailsPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.addBankDetails}
                              component={EditBankDetailsPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.editBankDetails}
                              component={EditBankDetailsPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.paymentSummary}
                              component={PaymentSummaryPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.viewBooking + "/:id"}
                              component={ViewBookingPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.patients}
                              component={PatientsListPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.addPatient}
                              component={AddPatientPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.editPractitionerConsents}
                              component={EditPractitionerConsents}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.practitionerConsents}
                              component={PractitionerConsentsPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={appRoutes.stocks}
                              component={InjectableStockPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={appRoutes.otherStocks}
                              component={OtherStockPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={`${appRoutes.editStock}/:_id`}
                              component={EditInjectableStockPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={`${appRoutes.editOtherStock}/:_id`}
                              component={EditOtherStockPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={`${appRoutes.viewStock}/:id`}
                              component={ViewInjectableStockPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={`${appRoutes.viewOtherStock}/:id`}
                              component={ViewOtherStockPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={appRoutes.addStock}
                              component={AddInjectableStockPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={appRoutes.addOtherStock}
                              component={AddOtherStockPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={appRoutes.productStocks}
                              component={ProductStockPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={appRoutes.addProductStock}
                              component={AddProductStockPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={`${appRoutes.editProductStock}/:_id`}
                              component={EditProductStockPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={`${appRoutes.viewProductStock}/:id`}
                              component={ViewProductStockPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.nurseProfile}
                              component={NurseProfilePage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.changePassword}
                              component={ChangePasswordPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.marketing}
                              component={MarketingPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.legal}
                              component={NurseLegalPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.reporting}
                              component={ReportingPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.logins}
                              component={LoginsPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.addLogin}
                              component={AddLoginsPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={`${appRoutes.editLogin}/:name`}
                              component={AddLoginsPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.clinics}
                              component={ClinicsPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.addClinic}
                              component={AddClinicsPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.nurseShifts}
                              component={NurseShiftsPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                          </Suspense>
                        </DashboardLayout>
                      </IndexLayout>
                    </Switch>
                  </Router>
                </Switch>
              </Route>
              <Route path="/pr">
                <Switch>
                  <Router>
                    <Switch>
                      <PRDashboardLayout>
                        <PatientDetailsPage>
                          <Suspense fallback={<Loading />}>
                            <RestrictedRoute
                              path={appRoutes.prDashboard}
                              component={ContactDetailsForm}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.prConsentForms}
                              component={ConsentForm}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.prAddNewConsentForm}
                              component={AddNewConsent}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.prMedicalHistory}
                              component={MedicalHistory}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={appRoutes.prS4DrugProtocols}
                              component={S4DrugProtocolsList}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={appRoutes.prAddS4DrugProtocolForm}
                              component={AddS4DrugProtocolFrom}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={appRoutes.prViewS4DrugProtocol + "/:id"}
                              component={ViewS4DrugProtocol}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={appRoutes.prOtherProtocols}
                              component={OtherProtocolsList}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={appRoutes.prAddOtherProtocol}
                              component={AddOtherProtocol}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              exact={true}
                              path={appRoutes.prViewOtherProtocol + "/:id"}
                              component={ViewOtherProtocol}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.prPractitionerConsents}
                              component={PractitionerConsentsPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.prEditPractitionerConsents}
                              component={EditPractitionerConsents}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.prBookingHistory}
                              component={BookingPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.prEditBookingHistory + "/:id"}
                              component={EditBookingPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.prViewBookingHistory + "/:id"}
                              component={ViewBookingPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.prCreateBooking}
                              component={EditBookingPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                            <RestrictedRoute
                              path={appRoutes.prPayment + "/:id"}
                              component={AddPaymentPage}
                              isLoggedIn={commonReducers.isLoggedIn}
                            />
                          </Suspense>
                        </PatientDetailsPage>
                      </PRDashboardLayout>
                    </Switch>
                  </Router>
                </Switch>
              </Route>
            </Fragment>
          )}

          <Redirect from="*" to="/login" />
        </Switch>
      </Router>
    </div>
  );
}
export default AppRoutes;
