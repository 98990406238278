import React from "react";
import PropTypes from "prop-types";
import { TextField, makeStyles, InputAdornment } from "@material-ui/core";

const useStyles = makeStyles({
  textField: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: ' 1px solid #e6e6e6',
    },
    "& .MuiInputBase-input": {
      fontFamily: 'AvenirNextRegular',
      fontSize: 14,
      color: '#656565'
    },
    "& .MuiOutlinedInput-input": {
      paddingTop: 9,
      paddingBottom: 9,
      paddingRight: 12,
      paddingLeft: 2,
      "&::placeholder": {
        color: "#999 !important",
        fontFamily: 'AvenirNextRegular',
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: ' 1px solid #e6e6e6 !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e6e6e6 !important'
    }
  },
});

const SearchInput = ({ className, ...props }) => {
  const classes = useStyles()
  return (
    <TextField
      className={classes.textField + " " + className}
      variant="outlined"
      size="small"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <i className="fas fa-search" style={{ color: '#999' }}></i>
          </InputAdornment>
        ),

      }}
      {...props}
    />
  );
}
SearchInput.defaultProps = {
  className: ""
};

SearchInput.propTypes = {
  className: PropTypes.string
};

export default SearchInput;
