import React, { useState, useEffect, Fragment } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Menu,
  AppBar,
  Hidden,
  MenuItem,
  Typography,
  makeStyles
} from "@material-ui/core";

import Modal from "../modalProps";
import appRoutes from "../../routes/appRoutes";
import PRSearchbar from "../../containers/PRSearchbar";
import { logoutUser } from "../../reduxStore/actions/commonAction";

import FullLogo from "../../assets/images/ITLogo_web.svg";
import SmallLogo from "../../assets/images/logo-small.png";
import ClinicLogo from "../../assets/images/clinic_logo.png";
import DemoUserImg from "../../assets/images/default-picture.png";

const useStyles = makeStyles({
  menuItem: {
    lineHeight: "30px",
    fontSize: "16px",
    fontWeight: "600",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.9px",
    color: "#7d7d7d",
    textDecoration: "none",
    padding: "8px 24px !important",
    borderBottom: "1px solid #dee2e6"
  }
});

const TopNavbar = ({ ipad, openNavDrawer }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { location } = history;

  const [isShowLogoutConfirmModal, setIsShowLogoutConfirmModal] = useState(
    false
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [isClinic, setIsClinic] = useState(
    location.pathname === appRoutes.onlineBooking ||
    location.pathname === appRoutes.bookingConfirmation
  );

  const commonReducers = useSelector(state => state.commonReducers);
  const isPR = history.location.pathname.includes("/pr/") ? true : false;

  useEffect(() => {
    setIsClinic(
      location.pathname === appRoutes.onlineBooking ||
      location.pathname === appRoutes.bookingConfirmation
    );
  }, [location.pathname]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    const openPRTabList = window.updateOpenPRTabList || [];
    openPRTabList.forEach(openPRTab => {
      openPRTab.close();
    });
    dispatch(logoutUser());
    history.push(appRoutes.login);
  };

  const goToProfile = () => {
    history.push(appRoutes.nurseProfile);
    handleClose();
  };

  const goToChangePassword = () => {
    history.push(appRoutes.changePassword);
    handleClose();
  };

  const goToLink = () => {
    if (commonReducers.auth.user?.userType === 'super_admin') {
      history.push(appRoutes.logins)
    } else if (commonReducers.auth.user?.userType === 'doctor') {
      history.push(appRoutes.practitionerConsents)
    } else {
      history.push(appRoutes.bookings)
    }
  }

  return (
    <Fragment>
      <AppBar
        position="fixed"
        color="inherit"
        className="top-nav-container top-nav top-nav-loggedIn pr-top-nav"
      >
        {commonReducers?.auth?.user ? (
          <>
            {" "}
            <i
              style={{ marginLeft: 8, marginRight: 16 }}
              className={ipad ? "fas fa-bars font-18 primary-color" : "d-none"}
              onClick={() => {
                openNavDrawer(true);
              }}
            />
            {!ipad &&
              <img
                onClick={goToLink}
                src={FullLogo}
                alt="Instant Consult Logo"
                className="full-logo"
                style={{ marginRight: 16 }}
              />}
            <Hidden xsUp>
              <Box width={1} />
            </Hidden>
            <Box mx="auto" className="pr-search-input-nav">
              <PRSearchbar />
            </Box>
            <Box width={1} pl={1} pr={isPR ? 2 : 0} className="navbar-text">
              <div className="contact">
                <div className="support-call">
                  <span style={{ marginRight: 8 }} className="insta-label">
                    Support
                  </span>
                  <i className="fas fa-phone-alt" />
                  <a
                    href={"tel:07 3555 5888"}
                    style={{ marginRight: 16, marginLeft: 4 }}
                    className="link insta-link-default insta-link-orange thin-font"
                  >
                    07 3555 5888
                  </a>
                </div>
                <div className="support-email">
                  <i style={{ marginRight: 4 }} className="fas fa-envelope" />
                  <a
                    className="link insta-link-default insta-link-orange thin-font"
                    href={"mailTo:support@instanttouch.com.au"}
                  >
                    support@instanttouch.com.au
                  </a>
                </div>
              </div>
            </Box>
            {!isPR && (
              <Box className="top-nav-right">
                <Box
                  component="span"
                  style={{ width: 160 }}
                  display="flex"
                  alignItems="center"
                  onClick={handleClick}
                >
                  <img
                    className="user-pic"
                    alt="user-pic"
                    src={
                      commonReducers &&
                        commonReducers.auth &&
                        commonReducers.auth.user.profilePic
                        ? commonReducers.auth.user.profilePic
                        : DemoUserImg
                    }
                  />
                  {commonReducers &&
                    commonReducers.auth &&
                    commonReducers.auth.user && (
                      <span className="insta-label font-15">
                        {`${commonReducers.auth.user.firstName} ${commonReducers.auth.user.lastName}`}
                      </span>
                    )}
                  <i style={{ marginLeft: 8 }} className="fas fa-caret-down" />
                </Box>
                <Menu
                  keepMounted
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                  }}
                  className="dropdown"
                >
                  <MenuItem className={classes.menuItem} onClick={goToProfile}>
                    <i className="fas fa-user"></i>&nbsp;&nbsp;&nbsp;My Profile
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={goToChangePassword}
                  >
                    <i className="fa fa-lock"></i>&nbsp;&nbsp;&nbsp;Change
                    Password
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => setIsShowLogoutConfirmModal(true)}
                  >
                    <i className="fas fa-sign-out-alt"></i>
                    &nbsp;&nbsp;&nbsp;Logout
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </>
        ) : (
          <Grid container alignItems="center">
            <Hidden xsDown>
              <Grid item sm={1} />
            </Hidden>
            <Grid item xs={4} sm={2}>
              {isClinic ? (
                <NavLink to="/">
                  <Hidden xsDown>
                    <img
                      src={ClinicLogo}
                      alt="Instant Consult Logo"
                      className="clinic-logo"
                    />
                  </Hidden>
                </NavLink>
              ) : (
                <NavLink to="/">
                  <Hidden xsDown>
                    <img
                      src={FullLogo}
                      alt="Instant Consult Logo"
                      className="full-logo"
                    />
                  </Hidden>
                  <Hidden smUp>
                    <img
                      src={SmallLogo}
                      alt="Instant Consult Logo"
                      className="small-logo"
                    />
                  </Hidden>
                </NavLink>
              )}
            </Grid>
            <Hidden xsDown>
              <Grid item sm={4} />
            </Hidden>
            <Grid item xs={8} sm={5}>
              {!isClinic && (
                <Box width={1} pl={1}>
                  <span style={{ marginRight: 8 }} className="insta-label gray">
                    Support
                  </span>
                  <i className="fas fa-phone-alt" />
                  <a
                    href="tel:0735555888"
                    style={{ marginRight: 16, marginLeft: 4 }}
                    className="link insta-link-default insta-link-orange thin-font"
                  >
                    07 3555 5888
                  </a>
                  <Hidden xsUp smDown>
                    <Box width={1} />
                  </Hidden>
                  <i style={{ marginRight: 4 }} className="fas fa-envelope" />
                  <a
                    href="mailTo:support@instanttouch.com.au"
                    style={{ marginRight: 16, marginLeft: 4 }}
                    className="link insta-link-default insta-link-orange thin-font"
                  >
                    support@instanttouch.com.au
                  </a>
                </Box>
              )}
            </Grid>
          </Grid>
        )}
      </AppBar>
      <Modal
        openModal={isShowLogoutConfirmModal}
        title="Logout"
        cancelText="Stay Logged In"
        cancelEvent={() => setIsShowLogoutConfirmModal(false)}
        okayText="Logout"
        okayEvent={logOut}
        useOneButton={false}
        modalBodyContent={
          <Box>
            <Typography
              style={{ marginBottom: 8 }}
              className="insta-label font-15"
            >
              Are you sure you want to logout?
            </Typography>
            <Typography className="insta-label font-15">
              Any Patient Records open in other tabs will automatically close
            </Typography>
          </Box>
        }
      />
    </Fragment>
  );
};

export default TopNavbar;
