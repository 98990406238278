import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';

const CustomButton = withStyles({
  root: {
    textTransform: 'none',
    color: '#fff !important',
    minWidth: '130px',
    borderRadius: '4px',
    backgroundColor: '#fea889',
    border: '1px solid #fea889',
    transition: '0.3s',
    fontSize: '13px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    height: '39px',
    fontFamily: 'AvenirNextMedium',
    '&:hover': {
      backgroundColor: '#fff',
      border: '1px solid #fea889',
      color: '#fea889 !important',
      boxShadow: 'none',
      outline: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#fff',
      border: '1px solid #fea889',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
})(Button);

const IconButton = props => (
  <CustomButton
    type={props.type}
    className={
      " insta-button-icon " +
      (props.disabled ? "disabled " : "") +
      props.className
    }
    style={{ ...props.style }}
    onClick={props.clickEvent}
    disabled={props.disabled}
  >
    <i className={"insta-icon-btn " + props.icon}></i>{" "}
    <span
      className="insta-btn-span"
      dangerouslySetInnerHTML={{ __html: props.title }}
    ></span>
  </CustomButton>
);

export default IconButton;

IconButton.defaultProps = {
  title: "Submit",
  className: "",
  type: "button",
  disabled: false,
  icon: ""
};

IconButton.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  clickEvent: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.string
};
