const ACTION_TYPE = {
  SET_HEAD_CLINIC_LIST: "SET_HEAD_CLINIC_LIST",
  SET_ALL_PROVIDERS_FROM_HEAD_CLINIC: "SET_ALL_PROVIDERS_FROM_HEAD_CLINIC"
};

export default ACTION_TYPE;

export const setHeadClinicList = payload => ({
  type: ACTION_TYPE.SET_HEAD_CLINIC_LIST,
  payload
});

export const setProvidersFromHeadClinicIds = payload => ({
  type: ACTION_TYPE.SET_ALL_PROVIDERS_FROM_HEAD_CLINIC,
  payload
})
