import React from "react";
import PropTypes from "prop-types";
import {
  Slide,
  Dialog,
  withStyles,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

const styles = theme => ({
  customModalContainer: {
    "& .MuiDialog-paperWidthSm": {
      width: 500
    },
    "& .MuiDialog-paperWidthMd": {
      width: 800
    },
    "& .MuiDialog-scrollPaper": {
      alignItems: "flex-start"
    },
    "& .MuiDialogTitle-root": {
      backgroundColor: "#fea889",
      color: "#fff",
      textAlign: "center",
      fontWeight: 600,
      fontSize: "18px",
      padding: theme.spacing(2)
    },
    "& .MuiTypography-h6": {
      fontWeight: 600,
      fontSize: "18px",
      fontFamily: "AvenirNextRegular"
    },
    "& .MuiDialogContent-root": {
      color: "#333333",
      textAlign: "center",
      fontSize: "15px",
      padding: "16px"
    },
    "& .MuiDialogActions-root": {
      justifyContent: "center",
      borderTop: "none",
      padding: "12px",
      paddingBottom: "20px"
    }
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1)
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CustomModal = ({
  size,
  title,
  classes,
  okayText,
  openModal,
  okayEvent,
  cancelText,
  cancelEvent,
  useOneButton,
  noFooterButtons,
  modalBodyContent
}) => (
  <Dialog
    fullWidth
    open={openModal}
    maxWidth={size}
    onClose={cancelEvent}
    className={`${classes.customModalContainer} ${openModal ? classes.fade : ""
      }`}
    TransitionComponent={Transition}
    transitionDuration={500}
  >
    <DialogTitle>
      {title}
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        size="small"
        onClick={cancelEvent}
      >
        <Close />
      </IconButton>
    </DialogTitle>
    <DialogContent>{modalBodyContent}</DialogContent>
    {!noFooterButtons && (
      <DialogActions>
        {!useOneButton && (
          <React.Fragment>
            <button className="insta-button-primary" onClick={okayEvent}>
              {okayText}
            </button>
            &nbsp;&nbsp;
          </React.Fragment>
        )}
        <button className="insta-button-primary" onClick={cancelEvent}>
          {cancelText}
        </button>
      </DialogActions>
    )}
  </Dialog>
);

CustomModal.defaultProps = {
  okayText: "OKAY",
  cancelText: "CANCEL",
  modalBodyContent: "",
  openModal: false,
  useOneButton: false,
  noFooterButtons: false,
  fullWidth: true
};

CustomModal.propTypes = {
  title: PropTypes.string.isRequired,
  okayText: PropTypes.string,
  cancelText: PropTypes.string,
  modalBodyContent: PropTypes.any,
  okayEvent: PropTypes.func,
  cancelEvent: PropTypes.func,
  openModal: PropTypes.bool,
  useOneButton: PropTypes.bool,
  noFooterButtons: PropTypes.bool
};

export default withStyles(styles)(CustomModal);
