import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Box, Grid } from "@material-ui/core";

import {
  setHeadClinicData,
  setConstantsData,
  setSubClinicData
} from "../../../reduxStore/actions/commonAction";
import { setProvidersFromHeadClinicIds } from "../../../reduxStore/actions/clinicAction";

import TopNavbar from '../../../components/common/TopNavbar'
import Footer from "../../../components/common/Footer";
import SideNavbar from "../../../components/common/SideNavbar";

import { USER_TYPE } from "../../../constants/common";


// utils
import deviceCheck from "../../../utils/checkDevice";
import { getClinicsList as getClinicsListApi } from "../../../api/clinics";
import { getConstants as getConstantsAPI } from "../../../api/others";
import { getProviderList } from "../../../api/users";

const DashboardLayout = props => {
  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState(false);

  const commonReducers = props.commonReducers;
  // Device infomation
  const deviceInfo = deviceCheck();
  // ipad drawer open/close

  const handleNavDrawer = isOpen => {
    setIsNavDrawerOpen(isOpen);
  };

  const getClinicList = async () => {
    try {
      const token = props.commonReducers?.auth?.accessToken || "";
      const res = await getClinicsListApi(token, { headClinic: props.commonReducers.auth.user.selectedClinics });
      await props.setHeadClinicData(res.data.doc);
      let usersSubClinicAccess = props.commonReducers.auth.user
        .selectedSubClinics
        ? props.commonReducers.auth.user.selectedSubClinics
        : null;
      var arr = [];
      if (
        usersSubClinicAccess &&
        props.commonReducers.auth.user.userType !== USER_TYPE.SUPER_ADMIN
      ) {
        let accessClinics = null;
        res.data.doc.forEach(element => {
          accessClinics = element.clinics.filter(el => {
            return usersSubClinicAccess.indexOf(el._id) > -1;
          });
          if (accessClinics.length) arr.push(accessClinics);
        });
      } else {
        res.data.doc.forEach(element => {
          arr.push(element.clinics);
        });
      }
      let mergedSubClinicArr = [].concat.apply([], arr);
      await props.setSubClinicData(mergedSubClinicArr);
    } catch (error) { }
  };

  const getConstants = async () => {
    try {
      const token = props.commonReducers?.auth?.accessToken || "";
      const res = await getConstantsAPI(token, {});
      props.setConstantsData(res.data[0]);
    } catch (error) { }
  };

  const getProviders = async () => {
    try {
      const token = props.commonReducers?.auth?.accessToken || "";
      const res = await getProviderList(token, { headClinicId: props.commonReducers.auth.user.selectedClinics })
      let providerArr = [];
      res.data.map((d, i) => {
        let obj = {
          _id: d._id,
          firstName: d.firstName,
          lastName: d.lastName,
          selectedClinics: d.selectedClinics,
          selectedSubClinics: d.selectedSubClinics,
          isBlocked: d.isBlocked,
        };
        providerArr.push(obj);
      });
      props.setProvidersFromHeadClinicIds(providerArr);
    } catch (error) { }
  }

  useEffect(() => {
    getClinicList();
    getConstants();
    getProviders();
  }, []);

  const customStyle = {
    marginLeft: deviceInfo.ipad === true ? "8px" : "0px",
  };

  return (
    <Fragment>
      <TopNavbar
        ipad={deviceInfo.ipad}
        openNavDrawer={handleNavDrawer}
      />
      <Box
        pr={2}
        className={
          deviceInfo.ipad === true
            ? isNavDrawerOpen === true
              ? "main-content isIpad isOpen"
              : "main-content isIpad"
            : "main-content"
        }
      >
        <Grid container spacing={deviceInfo.ipad === true ? 0 : 2}>
          <Grid item xs={deviceInfo.ipad === true ? 4 : 2}>
            <Box pl={1} className="left-column">
              <SideNavbar
                isICDoctor={props.commonReducers.auth.user.isICDoctor}
                userType={commonReducers.auth.userType}
                ipad={deviceInfo.ipad}
                closeNavDrawer={handleNavDrawer}
              />
            </Box>
          </Grid>
          <Grid item xs={deviceInfo.ipad === true ? 12 : 10}>
            <Box className="right-column" style={customStyle}>
              <Box px={2} className="contents">
                {props.children}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  commonReducers: state.commonReducers
});

const mapDispatchToProps = dispatch => ({
  setHeadClinicData: data => dispatch(setHeadClinicData(data)),
  setSubClinicData: data => dispatch(setSubClinicData(data)),
  setConstantsData: data => dispatch(setConstantsData(data)),
  setProvidersFromHeadClinicIds: data =>
    dispatch(setProvidersFromHeadClinicIds(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
