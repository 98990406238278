import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import momentTz from "moment-timezone";
import moment from "moment";
import { Box, Tooltip, Typography, } from "@material-ui/core";

// app routes
import appRoutes from "../../../routes/appRoutes";

// component
import ModalProps from "../../../components/modalProps";
import ICIconButton from "../../../components/iconButton";
import DefaultPicture from "../../../assets/images/default-picture.png";
import PatientNotificationForm from "../../PatientForms/PatientNotificationForm";
import { editContactDetailsPR } from "../../../reduxStore/actions/commonAction";
import { USER_TYPE, TEMPLATE_COMPONENT_IDENTIFIER } from "../../../constants/common";
import { getPatient, getPatientLogs, notifyPatient, setPatientLog } from "../../../api/patients";

const PatientLayoutHeader = (props) => {
  const [notificationModelStatus, setNotificationModelStatus] = useState(false);
  const [notificationSuccessModal, setNotificationSuccessModal] = useState(false);
  const [showFullAccessLogModal, setShowFullAccessLogModal] = useState(false);
  const [patientFullLog, setPatientFullLog] = useState([]);
  const [patientData, setPatientData] = useState('');
  const isDoctor = props.commonReducers.auth.userType === USER_TYPE.DOCTOR;


  useEffect(() => {
    const init = async () => {
      await props.editContactDetailsPR({
        isEditEnable: false
      });

      getPatientData();
    };

    init();
  }, [props.commonReducers.prPatientId]);


  const handleEdit = () => {
    props.editContactDetailsPR({
      isEditEnable: true
    });
    props.history.push(appRoutes.prDashboard);
  };

  const handleNotificationSend = async (formVal) => {
    setNotificationModelStatus(false);

    const patientId = props.commonReducers.prPatientId;
    if (patientId) {
      const dataObj = {
        notificationText: formVal.smsText,
        template: formVal.template,
        patientId: patientId,
        clinicId: patientData.defaultClinicId._id,
      };
      try {
        const token = props.commonReducers?.auth?.accessToken || "";
        await notifyPatient(token, dataObj);
        setNotificationSuccessModal(true);
      } catch (error) { }
    }
  };



  const getPatientData = async () => {
    if (props.commonReducers.prPatientId) {
      try {
        const token = props.commonReducers?.auth?.accessToken || "";
        const res = await getPatient(token, { patientId: props.commonReducers.prPatientId });
        setPatientData(res.data.doc[0]);
          if (res.data.doc[0]) {
            logPatientAccess(res.data.doc[0]._id);
          }
      } catch (error) {}
    }
  };

  const getFullLog = async () => {
    try {
      const params = {
        patientId: patientData._id
      };
      const token = props.commonReducers?.auth?.accessToken || "";
      const res = await getPatientLogs(token, params);
      let fullLog = [];
      if (res.data && res.data[0] && res.data[0].patientRecordAccessLog) {
        fullLog = res.data[0].patientRecordAccessLog.sort((a, b) => {
          if (moment(a.accessDate) > moment(b.accessDate)) return -1;
          if (moment(a.accessDate) < moment(b.accessDate)) return 1;
          return 0;
        });
      }
      setPatientFullLog(fullLog);
      setShowFullAccessLogModal(true);
    } catch (error) { }
  };

  const logPatientAccess = async (patientId) => {
    try {
      const token = props.commonReducers?.auth?.accessToken || "";
      await setPatientLog(token, { patientId });
    } catch (error) { }
  };

  const getWarnings = () => {
    const { prPatientWarningData } = props.commonReducers;

    if (!prPatientWarningData) {
      return null;
    }

    const {
      contactDetailIncomplete,
      consentFormsRequired,
      expiredConsentForm,
      expiredMedicalHistory,
      expiredS4DrugProtocol,
      medicalHistoryIncomplete,
      openMedicalHistory,
      practitionerConsentRequiresSign
    } = prPatientWarningData;

    if (contactDetailIncomplete === true)
      return <li style={{ color: '#fff' }} className="insta-label">Contact Details Incomplete</li>;

    if (medicalHistoryIncomplete === true)
      return <li style={{ color: '#fff' }} className="insta-label">Medical History Incomplete</li>

    if (consentFormsRequired === true)
      return <li style={{ color: '#fff' }} className="insta-label">Consent Forms Required</li>

    if (practitionerConsentRequiresSign === true)
      return <li style={{ color: '#fff' }} className="insta-label">Practitioner Consent Requires Signing</li>

    if (expiredMedicalHistory === true)
      return <li style={{ color: '#fff' }} className="insta-label">Expired Medical History</li>

    if (expiredConsentForm === true)
      return <li style={{ color: '#fff' }} className="insta-label">Expired Consent Form</li>

    if (expiredS4DrugProtocol === true)
      return <li style={{ color: '#fff' }} className="insta-label">Expired S4 Drug Protocol</li>

    if (openMedicalHistory === true)
      return <li style={{ color: '#fff' }} className="insta-label">Open Medical History</li>
  }

  return (
    <div className="patient-header-layout">
      <Box px={2} display='flex' justifyContent='space-between'>
        <Box width='75%' display='flex' >
          <img
            src={patientData?.profilePic || DefaultPicture}
            alt="patient-img"
            className="patient-img"
          />
          <Box>
            <Typography component={'span'} style={{ marginLeft: 12 }} className="insta-label big patient-name-lbl">{patientData ? patientData.firstName + " " + patientData.lastName : "FirstName LastName"}</Typography>
            <br />
            <Typography component='span' style={{ marginLeft: 12 }} className="insta-label grey"> {patientData && patientData.gender ? patientData.gender[0].toUpperCase() + patientData.gender.slice(1).toLowerCase() : "Gender"
            }</Typography>
            <Typography component='span' style={{ marginLeft: 12 }} className="insta-label grey">{
              patientData && patientData.dob
                ? "DOB: " +
                moment(patientData.dob).format("DD-MM-YYYY")
                : "Date Of Birth"
            }</Typography>
            <Typography component='span' style={{ marginLeft: 12 }} className="insta-label grey">{patientData && patientData.dob && "(" + moment().diff(moment(patientData.dob), "years") + " years of age)"}</Typography>
            <Typography component='span' style={{ marginLeft: 12 }} className="insta-label grey">{patientData && patientData.uin ? "UIN: " + patientData.uin : "UIN: "}</Typography>
          </Box>
        </Box>
        <Box width='25%' textAlign='right' display='flex' alignItems='flex-start' justifyContent='flex-end' className="exclamation-triangle-font">
          <i style={{ margin: 4, marginTop: 8, marginLeft: 8 }} className="only-icon double-dark fas fa-file cursor-pointer primary-color"
            onClick={() => getFullLog()}
          />
          {((props.commonReducers.prPatientWarningData &&
            props.commonReducers.prPatientWarningData
              .contactDetailIncomplete === true) ||
            (props.commonReducers.prPatientWarningData &&
              props.commonReducers.prPatientWarningData
                .consentFormsRequired === true) ||
            (props.commonReducers.prPatientWarningData &&
              props.commonReducers.prPatientWarningData
                .expiredConsentForm === true) ||
            (props.commonReducers.prPatientWarningData &&
              props.commonReducers.prPatientWarningData
                .consentFormRequiresPatientSign === true) ||
            (props.commonReducers.prPatientWarningData &&
              props.commonReducers.prPatientWarningData
                .expiredMedicalHistory === true) ||
            (props.commonReducers.prPatientWarningData &&
              props.commonReducers.prPatientWarningData
                .expiredS4DrugProtocol === true) ||
            (props.commonReducers.prPatientWarningData &&
              props.commonReducers.prPatientWarningData
                .medicalHistoryIncomplete === true) ||
            (props.commonReducers.prPatientWarningData &&
              props.commonReducers.prPatientWarningData
                .openMedicalHistory === true) ||
            (props.commonReducers.prPatientWarningData &&
              props.commonReducers.prPatientWarningData
                .practitionerConsentRequiresSign === true)) && (
              <Tooltip
                arrow
                placement="bottom"
                title={<ul style={{ marginTop: 12 }}>{getWarnings()}</ul>}
              >
                <i style={{ margin: 4, marginTop: 8, marginLeft: 8 }} className="fas fa-exclamation-triangle warning-icon" />
              </Tooltip>
            )}
          {!isDoctor && (
            <ICIconButton
              title=""
              icon="fas fa-edit"
              style={{ margin: 4, marginLeft: 8 }}
              className="only-icon double-dark"
              clickEvent={handleEdit}
            />
          )}
          {!isDoctor && (
            <ICIconButton
              title=""
              icon="fas fa-bell"
              style={{ margin: 4 }}
              className="only-icon warning"
              clickEvent={() => setNotificationModelStatus(true)}
            />
          )}

        </Box>
      </Box>
      <Box width={1} mt={2} borderBottom='1px solid #dee2e6' borderTop='1px solid #dee2e6' className="navigation-links">
        <Box component={'ul'} width={1} mb={0} pl={0} display='flex' style={{ listStyle: 'none' }}>
          <li className="nav-item patient-nav-items">
            <NavLink
              activeClassName="active"
              to={appRoutes.prDashboard}
              className="patient-record-nav-links item-multi-line"
              href="#"
            >
              Contact Details
            </NavLink>
          </li>
          <li className=" nav-item patient-nav-items">
            <NavLink
              activeClassName="active"
              to={appRoutes.prMedicalHistory}
              className="patient-record-nav-links item-multi-line"
              href="#"
            >
              Medical History
            </NavLink>
          </li>
          <li className="nav-item patient-nav-items">
            <NavLink
              activeClassName="active"
              to={appRoutes.prConsentForms}
              className="patient-record-nav-links item-multi-line"
              href="#"
            >
              Consent Forms
            </NavLink>
          </li>
          { props.commonReducers.auth.userType !== USER_TYPE.DERMAL_THERAPIST && 
          <>
            <li className="nav-item patient-nav-items">
              <NavLink
                activeClassName="active"
                to={appRoutes.prS4DrugProtocols}
                className="patient-record-nav-links item-multi-line"
                href="#"
              >
                S4 Drug Protocols
              </NavLink>
            </li>
            <li className="nav-item patient-nav-items">
              <NavLink
                activeClassName="active"
                to={{
                  pathname: appRoutes.prPractitionerConsents,
                  state: { isPR: true }
                }}
                className="patient-record-nav-links item-multi-line"
                href="#"
              >
                Practitioner Consents
              </NavLink>
            </li>
          </>
          }
          <li className="nav-item patient-nav-items">
            <NavLink
              activeClassName="active"
              to={appRoutes.prOtherProtocols}
              className="patient-record-nav-links item-multi-line"
              href="#"
            >
              Other Protocols
            </NavLink>
          </li>
          {props.commonReducers.auth &&
            props.commonReducers.auth.userType !== "doctor" && (
              <li className="nav-item patient-nav-items">
                <NavLink
                  activeClassName="active"
                  to={appRoutes.prBookingHistory}
                  className="patient-record-nav-links item-multi-line"
                  href="#"
                >
                  Booking History
                </NavLink>
              </li>
            )}
        </Box>
      </Box>

      {/* Notification Send Model */}
      <ModalProps
        openModal={notificationModelStatus}
        title="Send Notification"
        noFooterButtons={true}
        cancelEvent={() => setNotificationModelStatus(false)}
        modalBodyContent={
          <PatientNotificationForm
            componentIdentifier={TEMPLATE_COMPONENT_IDENTIFIER.PATIENT_RECORD_NOTIFICATION}
            patientId={patientData._id}
            clinicBookingUrl={patientData.defaultHeadClinicId?.url}
            onFormSubmit={handleNotificationSend}
          />
        }
      />

      {/* Notification Send Success modal */}
      <ModalProps
        openModal={notificationSuccessModal}
        title="Instant Touch"
        useOneButton={true}
        cancelEvent={() => setNotificationSuccessModal(false)}
        cancelText="Okay"
        modalBodyContent={
          <Typography className="insta-label font-15">Notification Sent Successfully</Typography>
        }
      />

      <ModalProps
        openModal={showFullAccessLogModal}
        title="Instant Touch"
        useOneButton={true}
        cancelEvent={() => setShowFullAccessLogModal(false)}
        cancelText="Okay"
        modalBodyContent={
          <div className='access-log-container'>
            {
              patientFullLog && patientFullLog.length ? patientFullLog.map((value, index) => {
                return (
                  <Box textAlign='center' key={index}>
                    <span>
                      {momentTz(value.accessDate)
                        .tz("Australia/Brisbane")
                        .format("DD-MM-YYYY hh:mm A")}
                    </span>
                    <span style={{ marginLeft: 12 }} >
                      {
                        value.userId.firstName + " " + value.userId.lastName
                      }
                    </span>
                  </Box>
                );
              }) :
                <p>No Data Found</p>
            }
          </div>
        }
      />
    </div>
  );
}

const mapStateToProps = state => ({
  commonReducers: state.commonReducers
});

const mapDispatchToProps = dispatch => ({
  editContactDetailsPR: data => dispatch(editContactDetailsPR(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PatientLayoutHeader));
