import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, TextField, Typography } from "@material-ui/core";

import Button from "../../components/button";
import appRoutes from "../../routes/appRoutes";

const ForgotPasswordForm = props => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .min(7, "Email must be of minimum length 7")
        .email("Please provide a valid email address")
        .required("Please enter the email address")
    }),
    onSubmit: values => {
      props.onFormSubmit(values);
    }
  });
  return (
    <Box component='form' display='flex' flexDirection='column' alignItems='center'
      onSubmit={formik.handleSubmit}
    >
      <Box mb={3}>
        <Typography className="insta-label font-15">A link will be sent to your email address so you can reset your password</Typography>
      </Box>
      <TextField
        id="email"
        name="email"
        type="text"
        label="Email Address *"
        className="ic-input"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        InputLabelProps={{ shrink: true }}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email && formik.errors.email}
      />
      <Box mt={3}>
        <Button title="Submit" type="submit" />
      </Box>
      <br />
      <NavLink
        to={appRoutes.login}
        style={{ color: "#999", fontSize: "14px", textDecoration: 'none' }}
        className="color-dark-grey"
        href="/"
      >
        <span>Back to Login</span>
      </NavLink>
      <Box mt={1} className="error">{props.serverErrorMsg}</Box>
    </Box>
  );
};

const mapStateToProps = props => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);

ForgotPasswordForm.defaultProps = {
  serverErrorMsg: ""
};

ForgotPasswordForm.propTypes = {
  onFormSubmit: PropTypes.func,
  serverErrorMsg: PropTypes.string
};
