import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';

const Footer = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState('');

  // Using Redux to get the commonReducers state
  const commonReducers = useSelector((state) => state.commonReducers);

  // Update the state with the privacy policy from Redux
  useEffect(() => {
    if (
      commonReducers.constantsData &&
      commonReducers.constantsData.adminPrivacyPolicy
    ) {
      setPrivacyPolicy(commonReducers.constantsData.adminPrivacyPolicy);
    }
  }, [commonReducers]);

  return (
    <Box width={1} bgcolor="#fff" className="footer">
      <Box px={2} pt={2} pb={1} width={1} borderTop="1px solid #dee2e6">
        <Typography component="span" className="insta-label ex-small gray">
          Instant Touch®
        </Typography>
        <span style={{ marginLeft: 4 }} className="insta-label gray ex-small">
          brought to you by Instant Consult
        </span>
        <span className="insta-label gray ex-small">&trade;</span>
        <a
          target="_blank"
          href={privacyPolicy}
          style={{ textDecoration: 'none', paddingLeft: 12 }}
          className="link text-primary cursor-pointer font-12"
        >
          Privacy Policy
        </a>
      </Box>
    </Box>
  );
};

export default Footer;
