// import * as storageApi from './storage'
import * as serverApi from './server'

// export const login = ({ username, password }) => serverApi.post('/v3/auth/login', { username, password })
//   .then((body) => {
//     storageApi.set('token', body.token)

//     return { user: { username } }
//   })

// export const me = () => serverApi.get('/v3/auth/me')
// export const quota = () => serverApi.get('/v3/auth/me/quota')

// export const logout = async () => {
//   await storageApi.del('token')
// }

// /user APIs
export const getUsers = (token, payload ) => serverApi.get(`/user`, token, payload);
export const addUser = (token, payload) => serverApi.post(`/user`, token, payload);
export const updateUser = (id, token, payload) => serverApi.put(`/user/${id}`, token, payload);
export const getUserById = (id, token, payload) => serverApi.get(`/user/${id}`, token, payload);
export const HPIIValidation = (token, payload) => serverApi.post(`/user/hpii`, token, payload);
export const userCSVExports = (token, payload) => serverApi.get(`/user/csv-exports`, token, payload);
export const updateBankDetails = (id, token, payload) => serverApi.put(`/user/${id}/bank-details`, token, payload);
export const editProfile = (token, payload) => serverApi.post(`/user/profile/edit`, token, payload);
export const getNurseListBelongToSubClinic = (token, payload) => serverApi.post(`/user/nurse`, token, payload);
export const getDoctorsInClinics = (token, payload) => serverApi.get(`/user/doctors-in-clinics`, token, payload);
export const getProviderList = (token, payload) => serverApi.post(`/user/providers`, token, payload);
export const getDoctorList = (token, payload) => serverApi.get(`/user/doctor/search`, token, payload);

// /user/admin APIs
export const nurseAvailableTimesForBooking = (token, payload) => serverApi.get(`/user/admin/nurse/availabletime`, token, payload);