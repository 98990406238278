/*
 * This file is edited by Vatsaly Patel
 * Note: If you are editing anything, please let me know - or do comment [it's compulsory]
 */

import { combineReducers } from "redux";
import commonReducers from "./reducers/commonReducers";
import clinic from "./reducers/clinicReducer";
import users from "./reducers/usersReducer";

const appReducer = combineReducers({
  commonReducers,
  clinic,
  users
});

const rootReducer = (_state, action) => {
  let state = _state;
  //here we will reset the whole redux state when the user will logout
  // if (action.type === LOGOUT_SUCCESS) {
  //   state = undefined;
  // }
  return appReducer(state, action);
};

export default rootReducer;
