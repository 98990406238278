const ACTION_TYPE = {
  SET_ELIGIBLE_USERS_TO_ADD_STOCK: "SET_ELIGIBLE_USERS_TO_ADD_STOCK",
  SET_DOCTORS_IN_CLINICS: "SET_DOCTORS_IN_CLINICS"
};

export default ACTION_TYPE;

export const setEligibleUsersToAddStock = payload => ({
  type: ACTION_TYPE.SET_ELIGIBLE_USERS_TO_ADD_STOCK,
  payload
});

export const setDoctorsInClinics = payload => ({
  type: ACTION_TYPE.SET_DOCTORS_IN_CLINICS,
  payload
});
