import React, { useState } from "react";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";

// containers
import PublicLayout from "../../containers/Layout/PublicLayout";
import LoginForm from "../../containers/LoginForm";

// images
import LogoImg from "../../assets/images/ITLogo_web.svg";

// app routes
import appRoutes from "../../routes/appRoutes";

import { login } from "../../api/auth";

import { USER_TYPE } from "../../constants/common";
import { loginUser } from "../../reduxStore/actions/commonAction";

const LoginPage = ({ history, loginUser }) => {
  const [apiCall, setApiCall] = useState(false);
  const [isEmailPasswordError, setIsEmailPasswordError] = useState(false);
  const [isBlockedUser, setIsBlockedUser] = useState(false);

  const handleLogin = formValues => {
    callLoginApi(formValues);
  };

  const callLoginApi = async loginData => {
    try {
      const res = await login(loginData);
      loginData["accessToken"] = res.data.token;
      loginData["userType"] = res.data.user.userType;
      loginData["user"] = res.data.user;
      loginUser({
        auth: loginData
      });
      if (res.data.user.userType === USER_TYPE.SUPER_ADMIN) {
        history.push("/superadmin" + appRoutes.logins);
      } else if (res.data.user.userType === USER_TYPE.DOCTOR) {
        history.push(
          "/" + res.data.user.userType + appRoutes.practitionerConsents
        );
      } else {
        history.push("/" + res.data.user.userType + appRoutes.bookings);
      }
    } catch (error) {
      const err = JSON.parse(error.message);
      if (err && err.message === "Invalid email or password") {
        setIsEmailPasswordError(true);
      } else {
        setIsBlockedUser(true);
      }

      setTimeout(() => {
        setIsEmailPasswordError(false);
        setIsBlockedUser(false);
      }, 5000);
    }
  };

  return (
    <PublicLayout>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className="login-page-container mb-5"
      >
        <Box className="lg-page-inner-container">
          <Box className="lp-header">
            <Box width={1}>Login</Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className="lp-content-container"
          >
            <Box width={1} textAlign="center" className="logo-img-container">
              <img src={LogoImg} alt="logo-img" />
            </Box>
            <Box width={1} className="lg-form-container">
              <LoginForm onFormSubmit={handleLogin} serverErrorMsg={""} />
            </Box>
            {isEmailPasswordError && (
              <p className="error">Invalid Email Address/Password</p>
            )}
            {isBlockedUser && (
              <p className="error">
                Please contact Instant Touch Support on 07 3555 5888
              </p>
            )}
          </Box>
        </Box>
      </Box>
    </PublicLayout>
  );
};

// Connect Redux actions and state
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  loginUser: data => dispatch(loginUser(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
