/*
 * This file is created by Vatsaly Patel
 * Note: If you are editing anything, please let me know - or do comment [it's compulsory]
 */

import React, { useEffect } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./reduxStore/store";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "react-input-range/lib/css/index.css";
import "react-multi-carousel/lib/styles.css";

import AppRoutes from "./routes";

function App() {

  useEffect(() => {
    try {
      window.onbeforeunload = function () {
        const openPRTabList = window.updateOpenPRTabList || [];
        openPRTabList.forEach(openPRTab => {
          openPRTab.close();
        });
      }
    } catch (e) {
      console.log("Something wrong during closing all the tabs from main file: ", e)
    }
  }, []);

  return (
    <div>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <AppRoutes />
          </MuiPickersUtilsProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
