import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";

// Containers
import ModalProps from "../../components/modalProps";
import PublicLayout from "../../containers/Layout/PublicLayout";
import ForgotPasswordForm from "../../containers/ForgotPasswordForm/ForgotPasswordForm";

// Images
import LogoImg from "../../assets/images/ITLogo_web.svg";

// App routes
import appRoutes from "../../routes/appRoutes";
import { forgotPassword } from "../../api/auth";

const ForgotPassPage = ({ history }) => {
  const [isEmailSentModal, setIsEmailSentModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleForgotPasswordForm = async(formVal) => {
    try {
      await forgotPassword(formVal);
      setIsEmailSentModal(true);
    } catch (error) {
      const err = JSON.parse(error.message)
      setErrorMessage(err.message);
      setTimeout(() => {
          setErrorMessage("");
      }, 4500);
    }
  };

  const handleModalClose = () => {
    setIsEmailSentModal(false);
    history.push(appRoutes.login);
  };

  return (
    <PublicLayout>
      <Box
        mb={5}
        display="flex"
        alignItems="center"
        justifyContent="center"
        className="login-page-container"
      >
        <Box className="lg-page-inner-container forgot-pass-page">
          <div className="lp-header">
            <Box>Forgot Password</Box>
          </div>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className="lp-content-container"
          >
            <Box width={1} textAlign="center" className="logo-img-container">
              <img src={LogoImg} alt="logo-img" />
            </Box>
            <Box width={1} textAlign="center" className="lg-form-container">
              <ForgotPasswordForm
                onFormSubmit={handleForgotPasswordForm}
                serverErrorMsg={errorMessage}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalProps
        openModal={isEmailSentModal}
        title="Instant Touch"
        cancelText="Okay"
        cancelEvent={handleModalClose}
        useOneButton={true}
        modalBodyContent={
          <Typography className="insta-label font-15">
            Password reset link has been sent to your registered email
          </Typography>
        }
      />
    </PublicLayout>
  );
};

export default ForgotPassPage;
