/*
 * This file is created by Vatsaly Patel
 * Note: If you are editing anything, please let me know - or do comment [it's compulsory]
 */

import React, { Fragment } from "react";

const DashboardLayout = props => {
  return <Fragment>{props.children}</Fragment>;
};

export default DashboardLayout;
