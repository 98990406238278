import ACTION_TYPE from "../actions/clinicAction";

const initialState = {
  headClinicList: [],
  allProvidersFromHeadClinicIds: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPE.SET_HEAD_CLINIC_LIST:
      return {
        ...state,
        headClinicList: action.payload
      };
    case ACTION_TYPE.SET_ALL_PROVIDERS_FROM_HEAD_CLINIC:
      return {
        ...state,
        allProvidersFromHeadClinicIds: action.payload
      }
    default:
      return state;
  }
}
