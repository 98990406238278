import React from "react";
import PropTypes from "prop-types";
import { Box, TextField, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import appRoutes from "../../routes/appRoutes";
import Button from "../../components/button";

const LoginForm = props => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMeCheckbox: false
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .min(7, "Email must be of minimum length 7")
        .email("Please provide a valid email address")
        .required("Please enter the email address"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters long")
        .required("Please enter the password")
    }),
    onSubmit: values => {
      props.onFormSubmit(values);
    }
  });

  return (
    <Box
      mx='auto'
      display="flex"
      flexDirection="column"
      alignItems="center"
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <Box width={1} mb={3}>
        <TextField
          id="email"
          name="email"
          type="text"
          label="Email Address"
          className="ic-input"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
          InputLabelProps={{
            shrink: true
          }}
          error={formik.touched.email && formik.errors.email ? true : false}
          helperText={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : ""
          }
        />
      </Box>
      <Box width={1} mb={3}>
        <TextField
          id="password"
          name="password"
          type="password"
          label="Password"
          className="ic-input"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
          InputLabelProps={{
            shrink: true
          }}
          error={
            formik.touched.password && formik.errors.password ? true : false
          }
          helperText={
            formik.touched.password && formik.errors.password
              ? formik.errors.password
              : ""
          }
        />
      </Box>
      <Box
        px={1}
        mb={3}
        width={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <FormControlLabel
          className="ic-checkbox-control"
          control={
            <Checkbox
              className="ic-checkbox"
              style={{ padding: 0, paddingRight: "5px" }}
              name="rememberMeCheckbox"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputProps={{ "aria-label": "primary checkbox" }}
              checked={formik.values.rememberMeCheckbox || false}
            />
          }
          label={
            <Typography
              style={{
                fontFamily: "AvenirNextRegular",
                fontSize: "14px !important",
                color: "#999999"
              }}
            >
              Remember Me
            </Typography>
          }
          labelPlacement="end"
        />

        <NavLink
          to={appRoutes.forgotPassword}
          style={{
            color: "#999",
            fontSize: "14px",
            textDecoration: "none",
          }}
          href="/"
        >
          <span>Forgot your password?</span>
        </NavLink>
      </Box>
      <br />
      <Button title="Login" type="submit" />
      <br />
      <Box className="error">{props.serverErrorMsg}</Box>
    </Box >
  );
};

const mapStateToProps = props => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

LoginForm.defaultProps = {
  serverErrorMsg: ""
};

LoginForm.propTypes = {
  onFormSubmit: PropTypes.func,
  serverErrorMsg: PropTypes.string
};
