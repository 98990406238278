import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { setPrPatientWarnings } from "../reduxStore/actions/commonAction";

import { checkPatientWarnings } from "../api/patients";

export function PatientWarningCheck(props) {


    useEffect(() => {
        const checkWarnings = async() => {
            if (props.checkWarning) {
                try {
                    const token = props.commonReducers?.auth?.accessToken || "";
                    const res = await checkPatientWarnings(token, { patientId: props.commonReducers.prPatientId });
                    props.setPrPatientWarnings(res.data.warningData);
                } catch (error) {

                }
            }
        }
        checkWarnings();
    }, [props.checkWarning])

    return (
        <Fragment>
        </Fragment>
    );
}

// get the store
const mapStateToProps = state => ({
    commonReducers: state.commonReducers
  });
  
  const mapDispatchToProps = dispatch => ({
    setPrPatientWarnings: data => dispatch(setPrPatientWarnings(data))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(PatientWarningCheck);