import * as serverApi from './server'

//Patient CRUD
export const getPatient = (token, payload) => serverApi.post(`/patient/search`, token, payload);
export const addPatient = (token, payload) => serverApi.post(`/patient/add`, token, payload);
export const editPatient = (token, payload) => serverApi.post(`/patient/edit`, token, payload);
export const setPatientDeleteStatus = (token, payload) => serverApi.post(`/patient/status`, token, payload);

//Patient Medical History
export const getMedicalHistory = (token, payload) => serverApi.get(`/medicalhistory`, token, payload);
export const addMedicalHistory = (token, payload) => serverApi.post(`/medicalhistory`, token, payload);
export const deleteMedicalHistory = (token, payload) => serverApi.post(`/medicalhistory/delete`, token, payload);

//Patient Consent Forms
export const getConsentForms = (token, payload) => serverApi.get(`/patient/consentform`, token, payload);
export const submitConsentForm = (token, payload) => serverApi.post(`/patient/consentform`, token, payload);
export const deleteConsentForm = (token, payload) => serverApi.post(`/patient/consentform/delete`, token, payload);

//Patient logs and notifications
export const getNotificationLogs = (token, payload) => serverApi.get(`/user/patient/notificationlogs`, token, payload);
export const notifyPatient = (token, payload) => serverApi.post(`/user/admin/notify/patient`, token, payload);
export const getPatientLogs = (token, payload) => serverApi.get(`/user/patient/accesslogs`, token, payload);
export const setPatientLog = (token, payload) => serverApi.post(`/user/patient/accesslog`, token, payload);
export const checkPatientWarnings = (token, payload) => serverApi.post(`/patient/check/warningsign`, token, payload);