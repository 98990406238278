/*
 * This file is created by Vatsaly Patel
 * Note: If you are editing anything, please let me know - or do comment [it's compulsory]
 */

export const NOTIFICATION_NUMBER = "NOTIFICATION_NUMBER";
// Auth Related
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

// Contact Details Edit in PR
export const EDIT_CONTACT_DETAILS = "EDIT_CONTACT_DETAILS";

export const PR_PATIENT_ID = "PR_PATIENT_ID";

export const HEAD_CLINIC_DATA = "HEAD_CLINIC_DATA";

export const SUB_CLINIC_DATA = "SUB_CLINIC_DATA";

export const PR_PATIENT_DATA = "PR_PATIENT_DATA";

export const CONSTANTS_DATA = "CONSTANTS_DATA";

export const SET_USER_BANK_DETAILS = "SET_USER_BANK_DETAILS";

export const SET_PR_PATIENT_WARNINGS = "SET_PR_PATIENT_WARNINGS";
