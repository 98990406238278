exports.USER_TYPE = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
  DOCTOR: "doctor",
  ADMIN_DOCTOR: "admin_doctor",
  NURSE: "nurse",
  PATIENT: "patient",
  ADMIN_NURSE: "admin_nurse",
  ADMIN_DERMAL_THERAPIST: 'admin_dermal_therapist',
  DERMAL_THERAPIST: 'dermal_therapist',
};
exports.LEGAL_TYPE = {
  ADMIN: "adminLegal",
  DOCTOR: "doctorLegal",
  ADMIN_NURSE: "nurseLegal",
  ADMIN_DERMAL_THERAPIST: 'dermalTherapistLegal',
};

exports.GENDER = {
  MALE: "male",
  FEMALE: "female"
};

exports.GENDER_CONVERT = {
  male: "Male",
  female: "Female"
};

exports.STATES = [
  {_id: 'ACT', val: 'Australian Capital Territory'},
  {_id: 'NSW', val: 'New South Wales'},
  {_id: 'NT',  val: 'Northern Territory'},
  {_id: 'QLD', val: 'Queensland'},
  {_id: 'SA',  val: 'South Australia'},
  {_id: 'TAS', val: 'Tasmania'},
  {_id: 'VIC', val: 'Victoria'},
  {_id: 'WA',  val: 'Western Australia'}
];

exports.COMMUNICATION_METHOD = {
  CALL: "Call",
  EMAIL: "Email"
};

exports.COMMUNICATION_TIME = {
  DURING_BUSINESS_H: 1,
  OUTSIDE_BUSINESS_H: 2,
  ON_WEEKENDS: 3,
  ANYTIME: 4
};

exports.CONVERT_COMMUNICATION_TIME = {
  1: "During Business Hours",
  2: "Outside Business Hours",
  3: "On Weekends",
  4: "Anytime"
};

exports.SERVICE_TYPES = {
  INITIAL_CONSULT: 1,
  TREATMENT: 2,
  POST_TREATMENT: 3
};

exports.BOOKING_STATUS = {
  ALL: 0,
  BOOKED: 1,
  CONFIRMED: 2,
  IN_SERVICE: 3,
  COMPLETED: 4,
  CANCELLED: 5,
  NO_SHOW: 6
};

exports.CONVERT_SERVICE_TYPE = {
  1: "Initial Consult",
  2: "Treatment",
  3: "Post Treatment"
};

exports.STOCK_STATUS = {
  IN_STOCK: 1,
  OUT_OF_STOCK: 2,
  EXPIRED: 3,
  RECALLED: 4
};

exports.STOCK_TYPE = {
  INJECTABLE: 1,
  OTHER: 2,
  PRODUCT: 3,
}

exports.PROTOCOL_TYPE = {
  S4_DRUG: 1,
  OTHER: 2
}

exports.STOCK_CHANGE_TYPE = {
  USE_STOCK: 1,
  UPDATE_STATUS: 2,
  UN_USE_STOCK: 3
};

exports.S4_DRUG_PROTOCOL_STATUS = {
  NEW: 1,
  CURRENT: 2,
  EXPIRED: 3
};

exports.CONVERT_S4_DRUG_PROTOCOL_STATUS = {
  1: "NEW",
  2: "CURRENT",
  3: "EXPIRED"
};

exports.CONSENT_FORM_STATUS = {
  OPEN: 1,
  COMPLETED: 2,
  EXPIRED: 3
};

exports.MEDICAL_HISTORY_STATUS = {
  OPEN: 1,
  COMPLETED: 2,
  EXPIRED: 3
};

exports.STOCK_CHANGE_METHOD = {
  TREATMENT: 1,
  MANUALLY: 2,
  AUTOMATICALLY: 3
};

exports.HPII_STATUS = {
  ACTIVE: "A",
  DEACTIVATED: "D",
  RETIRED: "R"
};

exports.USER_QUALIFICATION = {
  ADMIN: "Practice Administrator"
};

exports.PROMOTION_SELECT = [
  { _id: "everyone", val: "who are in our database" },
  {
    _id: "treatmentPreviously",
    val: "who have received treatments from us previously"
  },
  {
    _id: "specificTreatment",
    val: "who have received a specific treatment from us"
  },
  {
    _id: "specificTreatmentArea",
    val: "who have received a specific area of treatment from us"
  },
  { _id: "ageRange", val: "who are aged between" }
];

exports.CONSENT_FORM_STATUS_REVERSE = {
  1: "Open",
  2: "Completed",
  3: "Expired"
};

exports.STOCK_STATUS_REVERSE = {
  1: "In Stock",
  2: "Out of Stock",
  3: "Expired",
  4: "Recalled"
};

exports.SERVICE_TYPES_REVERSE = {
  1: "Initial Consult",
  2: "Treatment",
  3: "Post Treatment"
};

exports.putCommasOnNumber = (numberToPutComma) => {
  var number = numberToPutComma;
  var formattedNumber = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return formattedNumber;
}

exports.AUTOMATIC_CONFIRMATION_REMINDERS = [{
  id: 1,
  title: "[Sub-Clinic Name] Booking Confirmation - Your Initial Consult",
  url: "https://instant-touch-dev.s3.ap-southeast-2.amazonaws.com/undefined/1708368609328%5BSub-Clinic%20Name%5D%20Booking%20Confirmation%20%13%20Your%20Initial%20Consult.pdf",
},
{
  id: 2,
  title: "[Sub-Clinic Name] Booking Confirmation - Your Appointment",
  url: "https://instant-touch-dev.s3.amazonaws.com/undefined/1708368495177%5BSub-Clinic%20Name%5D%20Booking%20Confirmation%20-%20Your%20Appointment.pdf",
},
{
  id: 3,
  title: "Tick - Tock, Beauty O'Clock! Your Appointment is Just Around the Corner at [Sub-Clinic Name]!",
  url: "https://instant-touch-dev.s3.ap-southeast-2.amazonaws.com/undefined/1708368724407Tick-Tock%2C%20Beauty%20O%27Clock%21%20Your%20Appointment%20is%20Just%20Around%20the%20Corner%20at%20%5BSub-Clinic%20Name%5D%21.pdf",
},
{
  id: 4,
  title: "[Sub-Clinic Name] Post-Care - Your Recent Treatment",
  url: "https://instant-touch-dev.s3.ap-southeast-2.amazonaws.com/undefined/1708368663453%5BSub-Clinic%20Name%5D%20Post-Care%20-%20Your%20Recent%20Treatment.pdf",
},
{
  id: 5,
  title: "[Sub-Clinic Name] Booking Confirmation - Your Post-Treatment Consult",
  url: "https://instant-touch-dev.s3.amazonaws.com/documents/%5BSub-Clinic%20Name%5D%20Booking%20Confirmation%20-%20Your%20Post-Treatment%20Consult.pdf",
}];

exports.MAPPED_AUTOMATIC_CONFIRMATION_REMINDERS = {
  '1_DAYS_BEFORE_REMINDER': "Tick-Tock, Beauty O'Clock! Your Appointment is Just Around the Corner at [Sub-Clinic Name]!",
  '2_DAYS_BEFORE_REMINDER': "Tick-Tock, Beauty O'Clock! Your Appointment is Just Around the Corner at [Sub-Clinic Name]!",
  'POST_CARE_COMPLETED': "[Sub-Clinic Name] Post-Care - Your Recent Treatment",
  'INITIAL_CONSULT': "[Sub-Clinic Name] Booking Confirmation - Your Initial Consult",
  'TREATMENT': "[Sub-Clinic Name] Booking Confirmation - Your Appointment",
  'POST_TREATMENT': "[Sub-Clinic Name] Booking Confirmation - Your Post-Treatment Consult"
}

exports.MAPPED_DEFAULT_NOTIFICATION_RADIO_BTN_TITLES = {
  'hollywood': "Hollywood",
  // 'sass': "Sass",
  'laugh': "Laugh",
  'envy': "Envy",
  'OTHER': "Other"
}

exports.MAPPED_STOCK_UPDATE_STATUS = {
  "expired": "Expired",
  "unexplained": "Unexplained"
}
exports.TEMPLATE_COMPONENT_IDENTIFIER = {
  PATIENT_RECORD_NOTIFICATION: 1,
  BOOKING_RECORD_NOTIFICATION: 2,
  PROMOTIONAL_EMAILS: 3
}
exports.SMS_AND_EMAIL_TEMPLATES = [
  {
    title: "Hollywood",
    displayOnComponent: [
      this.TEMPLATE_COMPONENT_IDENTIFIER.PATIENT_RECORD_NOTIFICATION,
      this.TEMPLATE_COMPONENT_IDENTIFIER.BOOKING_RECORD_NOTIFICATION,
      this.TEMPLATE_COMPONENT_IDENTIFIER.PROMOTIONAL_EMAILS,
    ],
    template: "Ready to look as fabulous as your favorite Hollywood star? LA Lips® is here to make you feel like a million bucks (without the price tag)! Get ready for the red carpet treatment and prepare to dazzle. Book your appointment today and let us turn heads together!\n\nBook Now: <clinic-booking-url> \n\nLA Lips®💋 INJECTING NOW…"
  },
  // {
  //   title: "Sass",
  //   displayOnComponent: [
  //     this.TEMPLATE_COMPONENT_IDENTIFIER.PATIENT_RECORD_NOTIFICATION,
  //     this.TEMPLATE_COMPONENT_IDENTIFIER.BOOKING_RECORD_NOTIFICATION,
  //     this.TEMPLATE_COMPONENT_IDENTIFIER.PROMOTIONAL_EMAILS,
  //   ],
  //   template: "Inject some laughter into your beauty routine! Our cosmetic injectable treatments not only make you look amazing, but they also come with a free side of confidence and a pinch of sass. Embrace your inner diva and let our talented team work their magic. Trust us, you'll leave feeling like the queen of the catwalk!\n\nBook Now: <clinic-booking-url> \n\nLove from your #1 fan, LA Lips®💋 INJECTING NOW…"
  // },
  {
    title: "Laugh",
    displayOnComponent: [
      this.TEMPLATE_COMPONENT_IDENTIFIER.PATIENT_RECORD_NOTIFICATION,
      this.TEMPLATE_COMPONENT_IDENTIFIER.BOOKING_RECORD_NOTIFICATION,
      this.TEMPLATE_COMPONENT_IDENTIFIER.PROMOTIONAL_EMAILS,
    ],
    template: "Why settle for frown lines when you can have laugh lines instead? LA Lips® is your secret weapon against wrinkles and gloomy days. Let our skilled practitioners inject some joy into your life (and face) with our state-of-the-art treatments. Smile, laugh, and enjoy a younger-looking you!\n\nBook Now: <clinic-booking-url> \n\nLA Lips®💋 INJECTING NOW…"
  },
  {
    title: "Envy",
    displayOnComponent: [
      this.TEMPLATE_COMPONENT_IDENTIFIER.PATIENT_RECORD_NOTIFICATION,
      this.TEMPLATE_COMPONENT_IDENTIFIER.BOOKING_RECORD_NOTIFICATION,
      this.TEMPLATE_COMPONENT_IDENTIFIER.PROMOTIONAL_EMAILS,
    ],
    template: "Warning: our cosmetic injectable clinic has been known to cause excessive compliments and envious stares! Proceed with caution if you're not ready for a sudden boost in self-esteem and a barrage of 'Wow, you look incredible!' comments. Be ready to unleash your inner knockout, don't wait any longer. Join the ranks of our happy clients (if you aren’t already one) and prepare for a life filled with beauty and laughter!\n\nBook Now: <clinic-booking-url> \n\nLA Lips®💋 INJECTING NOW…"
  },
  {
    title: "Custom",
    displayOnComponent: [
      this.TEMPLATE_COMPONENT_IDENTIFIER.PROMOTIONAL_EMAILS,
    ],
    template: ""
  },
  {
    title: "Post Care\nAnti-Wrinkle",
    displayOnComponent: [
      this.TEMPLATE_COMPONENT_IDENTIFIER.BOOKING_RECORD_NOTIFICATION,
    ],
    template: "Hi doll. Just a quick follow-up regarding the treatment you had with us 2 weeks ago.\n\n• Are you happy with your treatment? \n• Have we achieved your desired outcome? \n• Do you still have desired movement when animating? \n\nIf you answered no to any of these questions, or simply want to tell or ask us something, please call us on <sub-clinic-phone>. Your results, confidence and safety are our number one priority.\n\n<sub-clinic> 💋"
  },
  {
    title: "Post Care Dermal Filler",
    displayOnComponent: [
      this.TEMPLATE_COMPONENT_IDENTIFIER.BOOKING_RECORD_NOTIFICATION,
    ],
    template: "Hi doll. Just a quick follow-up regarding the treatment you had with us yesterday.\n\n• Are you experiencing any tenderness or bruising?\n• Are you experiencing any pain of 5 or higher on a scale of 1-10?\n• Do you have any discolouration of the skin?\n• Are you experiencing any pain in an area that was not treated by us?\n\nIf you answered yes to any of these questions, or simply want to tell or ask us something, please call us on <sub-clinic-phone>. Your results, confidence and safety are our number one priority.\n\n<sub-clinic> 💋"
  },
  {
    title: "Client Retention",
    displayOnComponent: [
      this.TEMPLATE_COMPONENT_IDENTIFIER.BOOKING_RECORD_NOTIFICATION,
    ],
    template: "Hi <client-name>, it's <user-firstName> from LA Lips®💋 \n\nIt's been a few months since your last visit, and I wanted to do a quick check-in! We currently have exclusive promotions on our treatments. Now's the perfect time to schedule your next appointment. I don't want you to miss out. \n\nYou can visit our IG highlights https://www.instagram.com/stories/highlights/17896809266934231/ to find out more about our current promos 💖 \n\nBook in soon before my appointments fill up. Visit <clinic-booking-url> > Select '<sub-clinic>' > Select '<user-fullName>' or call me on <sub-clinic-phone>. \n\nHope to see you doll! x"
  },
];

exports.FIELD_TYPES = {
  OPEN_TEXT: "Open Text",
  MINS: "mins",
  MM: "mm",
  CALENDAR: "Calendar",
  DROPDOWN: "Drop Down"
};

exports.mapUserType = {
  'super_admin': 'Super Admin',
  'admin': 'Admin',
  'doctor': 'Doctor',
  'admin_doctor': 'Doctor',
  'nurse': 'Nurse',
  'admin_nurse': 'Nurse',
  'dermal_therapist': 'Dermal Therapist',
  'admin_dermal_therapist': 'Dermal Therapist'
}