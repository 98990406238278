import React, { Fragment, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Box,
  List,
  Divider,
  ListItem,
  Typography,
  CircularProgress
} from "@material-ui/core";

import SearchInput from "../../components/searchInput";
import { GENDER_CONVERT } from "../../constants/common";
import APP_CONTANTS from "../../constants";
import { getPatient } from "../../api/patients";

const PRSearchbar = props => {
  const searchInputContainerRef = useRef();
  const [inputInterval, setInputInterval] = useState(0);
  const [isPatientLoading, setIsPatientLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [isNoPatientFound, setIsNotPatientFound] = useState(false);
  const [searchText, setSearchText] = useState("");

  const updateSearchText = event => {
    setSearchText(event.target.value);
  };

  const handleKeyUp = event => {
    var searchText = event.target.value;
    if (inputInterval) clearTimeout(inputInterval);
    let setInterval = setTimeout(() => {
      if (searchText.trim().length > 0) {
        setIsPatientLoading(true);
        setIsDrawerOpen(true);
        getPatientRecords(searchText);
      } else {
        resetSearchParameters();
      }
    }, 700);

    setInputInterval(setInterval);
  };

  const resetSearchParameters = () => {
    setPatientList([]);
    setIsPatientLoading(false);
    setIsDrawerOpen(false);
    setSearchText("");
  };

  const getPatientRecords = async text => {
    let dataToSend = {
      searchTerm: text,
      limit: 20,
      skip: 0
    };

    const selectedSubClinics =
      props.commonReducers &&
      props.commonReducers.auth &&
      props.commonReducers.auth.user &&
      props.commonReducers.auth.user.selectedSubClinics
        ? props.commonReducers.auth.user.selectedSubClinics
        : [];

    if (selectedSubClinics.length) {
      dataToSend["selectedSubClinics"] = selectedSubClinics;
    }
    try {
      const token = props.commonReducers?.auth?.accessToken || "";
      const res = await getPatient(token, dataToSend);
      setIsPatientLoading(false);
      setPatientList(res.data.doc);
      setIsNotPatientFound(res.data.doc.length ? false : true);
    } catch (error) {
      setIsPatientLoading(false);
    }
  };

  const selectedPatient = id => {
    const tab = window.open(APP_CONTANTS.prUrl + "/" + id, "_blank");
    const prevUpdateOpenPRTabList = window.updateOpenPRTabList || [];
    window.updateOpenPRTabList = [...prevUpdateOpenPRTabList, tab];
    resetSearchParameters();
  };

  const handleOutSideClick = event => {
    if (
      searchInputContainerRef &&
      event.target &&
      !searchInputContainerRef.current.contains(event.target)
    ) {
      resetSearchParameters();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutSideClick);
    return () => {
      document.removeEventListener("click", handleOutSideClick);
    };
  });

  return (
    <Box
      className="pr-search-component-container"
      ref={searchInputContainerRef}
    >
      <SearchInput
        placeholder="Search Patient Records"
        className="search-input"
        onChange={updateSearchText}
        onKeyUp={handleKeyUp}
        value={searchText}
      />
      <div className="relative-list-container">
        <div className="patient-list-container">
          <List className={isDrawerOpen ? "list-group border-gray" : "d-none"}>
            <ListItem className={isPatientLoading ? "" : "d-none"}>
              <Box p={0.5} width={1} textAlign="center">
                <CircularProgress disableShrink className="primary-loader" />
              </Box>
            </ListItem>
            {isNoPatientFound && (
              <ListItem>
                <Box width={1} textAlign="center">
                  <Typography className="insta-label font-15">
                    No Data Found
                  </Typography>
                </Box>
              </ListItem>
            )}
            {patientList.map((patient, index) => {
              return (
                <Fragment key={index}>
                  <ListItem
                    style={{ padding: 16 }}
                    button
                    onClick={() => selectedPatient(patient._id)}
                  >
                    <Box width={1} className="cursor-pointer">
                      <Box width={1}>
                        <Typography className="insta-label font-15">{`${patient.firstName} ${patient.lastName}`}</Typography>
                      </Box>
                      <Box width={1}>
                        <Typography
                          component="span"
                          className="insta-label font-15"
                        >
                          {patient.gender
                            ? GENDER_CONVERT[patient.gender]
                            : "-"}
                        </Typography>

                        <Typography
                          component="span"
                          style={{ marginLeft: 16 }}
                          className="insta-label font-15"
                        >{`DOB: ${
                          patient.dob
                            ? moment(patient.dob).format("Do MMM YYYY")
                            : "-"
                        }`}</Typography>

                        <Typography
                          component="span"
                          style={{ marginLeft: 16 }}
                          className="insta-label font-15"
                        >{`UIN: ${patient.uin}`}</Typography>
                      </Box>
                    </Box>
                  </ListItem>
                  {patientList.length !== index + 1 && <Divider />}
                </Fragment>
              );
            })}
          </List>
        </div>
      </div>
    </Box>
  );
};

const mapStateToProps = state => ({
  commonReducers: state.commonReducers
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PRSearchbar);
