/*
 * This file is created by Vatsaly Patel
 * Note: If you are editing anything, please let me know - or do comment [it's compulsory]
 */

import {
  NOTIFICATION_NUMBER,
  LOGIN,
  LOGOUT,
  EDIT_CONTACT_DETAILS,
  PR_PATIENT_ID,
  HEAD_CLINIC_DATA,
  PR_PATIENT_DATA,
  CONSTANTS_DATA,
  SET_USER_BANK_DETAILS,
  SUB_CLINIC_DATA,
  SET_PR_PATIENT_WARNINGS
} from "../constants/commonConstant";

const initialState = {
  totalNotification: 0, // not used for now - we will remove this
  auth: null, // store all user data in this
  isLoggedIn: false, // used to check user is logged in or not
  prContactDetailsEdit: false, // default contact details edit false
  prPatientId: null,
  prPatientData: null,
  headClinicData: null,
  subClinicData: null,
  prPatientWarningData: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_NUMBER:
      return {
        ...state,
        totalNotification: action.payload.totalNotification
      };
    case LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        auth: action.payload.auth
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        auth: null
      };
    case EDIT_CONTACT_DETAILS:
      return {
        ...state,
        prContactDetailsEdit: action.payload.isEditEnable
      };
    case PR_PATIENT_ID:
      return {
        ...state,
        prPatientId: action.payload
      };

    case PR_PATIENT_DATA:
      return {
        ...state,
        prPatientData: action.payload
      };
    case HEAD_CLINIC_DATA:
      const sortedHeadClinic = action.payload.sort((a, b)=> {
        if (a.clinicName < b.clinicName) return -1;
        if (a.clinicName > b.clinicName) return 1;
        return 0;
      });

      const headClinicData = [];

      for (let subClinic of sortedHeadClinic) {
        headClinicData.push({
          ...subClinic,
          clinics: subClinic.clinics.sort((a, b)=> {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          })
        })
      }

      return {
        ...state,
        headClinicData
      };
    case SUB_CLINIC_DATA:
      return {
        ...state,
        subClinicData: action.payload.sort((a, b)=> {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })
      };
    case CONSTANTS_DATA:
      return {
        ...state,
        constantsData: action.payload
      };
    case SET_USER_BANK_DETAILS:
      return {
        ...state,
        auth: {
          ...state.auth,
          user: {
            ...state.auth.user,
            bankDetails: action.payload
          }
        }
      };
    case SET_PR_PATIENT_WARNINGS:
      return {
        ...state,
        prPatientWarningData: action.payload
      };
    default:
      return state;
  }
}
