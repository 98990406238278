import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  FormLabel,
  TextField,
  makeStyles,
  FormControl
} from "@material-ui/core";

import Button from "../../components/button";

const useStyles = makeStyles(theme => ({
  formControl: {
    "& .MuiFormLabel-root": {
      fontSize: 14,
      marginBottom: 8,
      fontFamily: "AvenirNextRegular",
      color: "rgba(160, 159, 159, .839216)",
      "& .MuiFormLabel-asterisk": {
        color: "#e84c25"
      }
    }
  }
}));
const CreatePasswordForm = props => {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(
          8,
          "Password should be at least 8 and maximum 20 characters long and should contain one number, one uppercase and one lowercase"
        )
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/,
          "Password should be at least 8 and maximum 20 characters long and should contain one number, one uppercase and one lowercase"
        )
        .required("Please enter the password"),
      confirmPassword: Yup.string()
        .min(
          8,
          "Password should be at least 8 and maximum 20 characters long and should contain one number, one uppercase and one lowercase"
        )
        .required("Please enter the confirm password")
        .oneOf([Yup.ref("password")], "Both Passwords should match")
    }),
    onSubmit: values => {
      props.onFormSubmit(values);
    }
  });
  return (
    <Box
      mx='auto'
      component="form"
      display="flex"
      flexDirection="column"
      alignItems="center"
      onSubmit={formik.handleSubmit}
    >
      <FormControl fullWidth className={classes.formControl}>
        <FormLabel required>Enter Password</FormLabel>
        <TextField
          id="password"
          name="password"
          type="password"
          label=""
          className="ic-input"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={
            formik.touched.password &&
            formik.errors.password &&
            formik.errors.password
          }
        />
      </FormControl>
      <FormControl style={{ marginTop: 24 }} fullWidth className={classes.formControl}>
        <FormLabel required>Confirm Password</FormLabel>
        <TextField
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          label=""
          className="ic-input"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirmPassword}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword &&
            formik.errors.confirmPassword &&
            formik.errors.confirmPassword
          }
        />
      </FormControl>
      <Box mt={3}>
        <Button title="Submit" type="submit" />
      </Box>
      <Box className="error">{props.serverErrorMsg}</Box>
    </Box>
  );
};

const mapStateToProps = props => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePasswordForm);

CreatePasswordForm.defaultProps = {
  serverErrorMsg: ""
};

CreatePasswordForm.propTypes = {
  onFormSubmit: PropTypes.func,
  serverErrorMsg: PropTypes.string
};
