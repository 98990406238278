import React, { Fragment } from "react";

import TopNavbar from "../../../components/common/TopNavbar";
import Footer from "../../../components/common/Footer";


const PublicLayout = props => {
  return (
    <Fragment>
      <TopNavbar />
      <div className="public-layout-container">
        {props.children}
      </div>
      <Footer />

    </Fragment>
  );
};

export default PublicLayout;
