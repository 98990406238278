import React, { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";

// containers
import PublicLayout from "../../containers/Layout/PublicLayout";
import CreatePasswordForm from "../../containers/CreatePasswordForm";

// images
import LogoImg from "../../assets/images/InstantTouchLogo.png";

// app routes
import appRoutes from "../../routes/appRoutes";

import ModalProps from "../../components/modalProps";
import { createPassword } from "../../api/auth";

const CreatePassPage = ({ location, history }) => {
  const [token, setToken] = useState("");
  const [isEmailResetModal, setIsEmailResetModal] = useState(false);

  const handleForgotPasswordForm = async formVal => {
    try {
      formVal["token"] = token;
      await createPassword(formVal);
      setIsEmailResetModal(true);
    } catch (error) {}
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenFromQuery = queryParams.get("token");
    if (tokenFromQuery) {
      setToken(tokenFromQuery);
    }
  }, [location.search]);

  const handleModalClose = () => {
    setIsEmailResetModal(false);
    history.push(appRoutes.login);
  };

  return (
    <PublicLayout>
      <Box
        mb={5}
        display="flex"
        alignItems="center"
        justifyContent="center"
        className="login-page-container"
      >
        <Box className="lg-page-inner-container forgot-pass-page">
          <div className="lp-header">Recover Password</div>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className="lp-content-container"
          >
            <Box width={1} textAlign="center" className="logo-img-container">
              <img src={LogoImg} alt="logo-img" />
            </Box>
            <Box width={1} className="lg-form-container">
              <CreatePasswordForm
                onFormSubmit={handleForgotPasswordForm}
                serverErrorMsg=""
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalProps
        openModal={isEmailResetModal}
        title="Instant Touch"
        cancelText="Okay"
        cancelEvent={handleModalClose}
        useOneButton={true}
        modalBodyContent={
          <Typography className="insta-label font-15">
            New Password Updated Successfully
          </Typography>
        }
      />
    </PublicLayout>
  );
};

export default CreatePassPage;
