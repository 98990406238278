import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import TopNavbar from "../../../components/common/TopNavbar";
import Footer from "../../../components/common/Footer";

import { setPrPatientId } from "../../../reduxStore/actions/commonAction";
import PatientWarningCheck from "../../../apiCalls/patientWarningCheck";

const PRDashboardLayout = props => {
  const [checkWarning, setCheckWarning] = useState("");
  useEffect(() => {
    let parts = props.location.pathname.split('/');
    let patientId = parts[parts.length - 1];
    if (patientId.length === 24 && props.location.pathname.includes('pr/patient')) {
      props.setPrPatientId(patientId);
    }
    setCheckWarning(true);
  }, [])

  return (
    <Fragment>
      <Helmet>
        <title>Instant Touch Patient Records</title>
      </Helmet>
      <TopNavbar />
      <div className="pr-main-content">{props.children}</div>
      <Footer />
      <PatientWarningCheck checkWarning={checkWarning} />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  commonReducers: state.commonReducers
});

const dispatchToProps = dispatch => ({
  setPrPatientId: payload => dispatch(setPrPatientId(payload)),
})

export default connect(mapStateToProps, dispatchToProps)(PRDashboardLayout);
