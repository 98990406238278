import React from "react";
import PatientLayoutHeader from "../../../containers/PR/PatientLayoutHeader/PatientLayoutHeader";
import { Box, Container } from "@material-ui/core";

const PatientDetailsPage = ({ children }) => {
 
  return (
    <Container fixed className="patient-detail-layout">
      <Box py={2} mb={5}>
        <PatientLayoutHeader />
        {children}
      </Box>
    </Container>
  );
};

export default PatientDetailsPage;
